/* ./src/components/elements/Content.jsx */
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function Content() {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Outlet /> {/* Las rutas anidadas se renderizarán aquí */}
    </Box>
  );
}
export default Content;
