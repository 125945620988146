import { Typography, Radio } from "@mui/material";

const RadioButton = ({ option, name, value, onChange }) => {
  // Formatea el nombre de la opción para que coincida con el nombre del archivo
  //const formattedOption = option.replace(" ", "_").toLowerCase();
  const formattedOption = option
    .replace(/[^a-z0-9 ]/gi, "")
    .replace(/\s+/g, "_")
    .toLowerCase();
  return (
    <label className={`my-radio-button ${value === option ? "active" : ""}`}>
      <Radio
        id={name + option}
        value={option}
        checked={value === option}
        onChange={() => onChange(option)}
        className="visually-hidden"
      />
      <div className="icon-container">
        <img
          width={50}
          src={"assets/icons/" + formattedOption + ".png"}
          alt={option}
        />
      </div>
      <Typography
        className="label"
        color="primary.dark"
        sx={{ lineHeight: "15px" }}
      >
        {option}
      </Typography>
    </label>
  );
};

export default RadioButton;
