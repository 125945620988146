/* ./src/index.js */
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "contexts/AuthContext"; // importa tu AuthProvider

import "./styles/global.css";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./styles/main.scss";
import MainLayout from "./components/MainLayout";
import DashboardLayout from "./components/DashboardLayout";
import DashboardPage from "./components/elements/pages/DashboardPage";
import UsersPage from "./components/elements/pages/UsersPage";

const root = createRoot(document.getElementById("root"));

root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes>
          {/* Rutas principales */}
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contactus" element={<Contact />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/testimonials" element={<Testimonials />} />
          </Route>
          {/* Rutas del panel de control */}
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="users" element={<UsersPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  </Router>
);
