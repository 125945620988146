import { Box, Typography } from "@mui/material";
import React from "react";

const Checkbox = ({ option, name, checked, onChange }) => {
  return (
    <label className={`checkbox ${checked ? "active" : ""}`}>
      <input
        type="checkbox"
        name={name}
        value={option}
        checked={checked}
        onChange={onChange}
        className="visually-hidden"
      />
      <div className="checkbox-icon">
        <span className="checkmark">&#10004;</span>
      </div>
      <Box mr={5}>
        <Typography className="label">{option}</Typography>
      </Box>
    </label>
  );
};

export default Checkbox;
