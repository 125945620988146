// services/api.js
import axios from "axios";

var api = process.env.REACT_APP_API_URL;
var apiKey = process.env.REACT_APP_API_KEY;

export const getApi = async (url) => {
  console.log(api + url);
  return axios
    .get(api + url, {
      headers: {
        "Api-Key": apiKey,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

// Mantenemos la función postApi para referencia
export const postApi = async (formData, url) => {
  console.log(api + url);
  return axios
    .post(api + url, formData, {
      headers: {
        "Api-Key": apiKey,
        "Content-Type": "multipart/form-data", // Importante para subir archivos
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
