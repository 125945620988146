import React, { useState, useContext } from "react";
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "contexts/AuthContext";
import Swal from "sweetalert2";
const DrawerComp = ({ pages, onOpen }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const { state, dispatch } = useContext(AuthContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const updatedPages = [
    ...pages,
    /* {
      label: "Login",
      path: "/login"
    },
    {
      label: "SignUp",
      path: "/signup"
    } */
  ];
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    Toast.fire({
      icon: "success",
      title: "You have successfully closed the session",
    });
  };
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ width: "50%" }}
        PaperProps={{
          style: {
            width: "50%",
          },
        }}
      >
        <List>
          {updatedPages.map((page, index) => (
            <ListItemButton key={index} onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <Link to={page.path} style={{ textDecoration: "none", color: "inherit" }}>
                  <ListItemText>{page.label}</ListItemText>
                </Link>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.lightplus,
            width: "75%",
            mx: "auto",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.common.white,
            },
          }}
          onClick={state.isLoggedIn ? handleLogout : onOpen}
        >
          {state.isLoggedIn ? "Logout" : "Login / Sign Up"}
        </Button>
      </Drawer>
      <IconButton sx={{ color: "white", marginLeft: "auto" }} onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default DrawerComp;
