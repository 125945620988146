import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Box, Grid, Container, Typography, Stepper, Step, StepLabel, TextField, useTheme, useMediaQuery, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material/styles";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import validationRules from "./validationRules";
import Swal from "sweetalert2";

import RadioButton from "./elements/RadioButton";
import ToggleButton from "./elements/ToggleButton";
import Checkbox from "./elements/Checkbox";
import NumberInput from "./elements/NumberInput";
import UploadButton from "./elements/UploadButton";

import { IMaskInput } from "react-imask";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

import sendForm from "./../assets/ilustraciones/sendForm.svg";

import { postApi } from "./../services/api";

import styles from "styles/StepForm.module.scss";
import { AuthContext } from "contexts/AuthContext"; // Importa tu AuthContext

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref} // Asegúrate de pasar el ref al componente IMaskInput
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const steps = [
  {
    title: "Personal Information",
    ref: React.createRef(),
    fields: [
      {
        label: "First Name",
        name: "firstName",
        type: "text",
      },
      {
        label: "Last Name",
        name: "lastName",
        type: "text",
      },
      {
        label: "Phone Number",
        name: "phoneNumber",
        type: "tel",
      },
      {
        label: "Email",
        name: "email",
        type: "email",
      },
      {
        label: "Confirm Email",
        name: "confirmEmail",
        type: "email",
      },
      {
        label: "Address",
        name: "address",
        type: "text",
      },
    ],
  },
  {
    title: "House Details",
    ref: React.createRef(),
    fields: [
      {
        label: "Type of Home",
        name: "typeOfHome",
        type: "radio",
        options: ["Single family", "Apartment", "Townhouse", "Other"],
      },
      {
        label: "Places to clean",
        name: "placesToClean",
        type: "checkbox",
        options: [
          "Family room",
          "Living Room",
          "Dining Room",
          "Kitchen",
          "Stairways",
          "Attic",
          "Basement",
          "Garage",
          "Utility Room",
          "Laundry Room",
          "Office",
          "Hallway",
          "Rec. Room",
          "Inside Glass Shining",
          "Outdoor Window Cleaning",
        ],
      },
      {
        label: "How many bedrooms?",
        name: "numberOfBedrooms",
        type: "number",
      },
      {
        label: "How many bathrooms?",
        name: "numberOfBathrooms",
        type: "number",
      },
      {
        label: "Make your choice - Pets",
        name: "hasPets",
        type: "radio",
        options: ["yes", "no"],
      },
      {
        label: "Make your choice - Carpet",
        name: "hasCarpet",
        type: "radio",
        options: ["yes", "no"],
      },
    ],
  },
  {
    title: "Cleaning Preferences",
    ref: React.createRef(),
    fields: [
      {
        label: "Type of Services Desired",
        name: "typeOfServices",
        type: "radio",
        options: ["Standard", "Deep", "Party / special occasions", "Moving In/Out", "Other"],
      },
      {
        label: "Preferred Days",
        name: "preferredDays",
        type: "date",
      },
      {
        label: "Preferred Time",
        name: "preferredTime",
        type: "time",
      },
      {
        label: "Will You Provide Cleaning Products?",
        name: "willProvideCleaningProducts",
        type: "radio",
        options: ["yes", "no"],
      },
    ],
  },
  {
    title: "Additional Information",
    ref: React.createRef(),
    fields: [
      {
        label: "Are there any other details you'd like to share?",
        name: "additionalDetails",
        type: "textarea",
      },
      {
        label: "Additional Services Provided at Additional Fees",
        name: "additionalServices",
        type: "checkbox",
        options: [
          "Oven cleaning inside",
          "Refrigerator cleaning inside",
          "Clean up the cutter",
          "MiniBlings",
          "Floor waxing",
          "Cabinets cleaning inside",
          "Wall washing",
          "Dish cleaning",
          "Microwave inside",
          "Other",
        ],
      },
      {
        label: "If you want to send photos of what we are going to clean, please upload your images here",
        name: "images",
        type: "file",
      },
    ],
  },
];

const StepForm = () => {
  const { state } = useContext(AuthContext);

  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [sendMail, setSendMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const submitForm = async (values) => {
    try {
      setIsLoading(true); // Habilitar el estado de carga
      // Mueve la creación de FormData aquí
      const formData = new FormData();

      // Añade todos tus valores de formulario a formData
      Object.keys(values).forEach((key) => {
        // Comprueba si el valor es una matriz
        if (Array.isArray(values[key])) {
          if (key === "images") {
            values[key].forEach((image, index) => {
              formData.append(`images[${index}]`, image.file, image.name);
            });
          } else {
            formData.append(key, JSON.stringify(values[key]));
          }
        } else {
          formData.append(key, values[key]);
        }
      });

      const response = await postApi(formData, "/cleaning-service");
      console.log(response);
      if (response.status === "success") {
        setSendMail(true);
        setIsLoading(false);
        setActiveStep((prevStep) => prevStep + 1);
        Swal.fire("¡Success!", "The service quotation has been sent.", "success"); // Muestra el mensaje de éxito
      } else if (response.status === "error") {
        let errorMessage = response.message;
        // Iterar sobre los errores de los campos y concatenar los mensajes
        let fieldErrorMessages = Object.values(response.errors).flat().join("<br>");
        Toast.fire({
          icon: "error",
          html: `${errorMessage}: <br> ${fieldErrorMessages}`,
        });
        setIsLoading(false);
      } else {
        Toast.fire({
          icon: "error",
          title: "There was an error submitting your form, please contact technical support.",
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response) {
        // El servidor respondió con un estado fuera del rango 2xx
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: "Error while sending the form, please contact technical support." + error,
          icon: "error",
          confirmButtonText: "Ok",
        });
        setIsLoading(false);
      } else {
        // Algo ocurrió en la configuración de la petición que provocó un error
        console.error("Error while sending the form", error);
        Swal.fire({
          title: "Error!",
          text: "Error while sending the form, please contact technical support." + error,
          icon: "error",
          confirmButtonText: "Ok",
        });
        setIsLoading(false);
      }
    }
  };

  const initialFormValues = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      confirmEmail: "",
      address: "",
      typeOfHome: "",
      placesToClean: [],
      numberOfBedrooms: 0,
      numberOfBathrooms: 0,
      hasPets: "no",
      hasCarpet: "no",
      typeOfServices: "",
      preferredDays: dayjs().format("MMM D, YYYY"),
      preferredTime: dayjs().format(),
      willProvideCleaningProducts: "no",
      additionalDetails: "",
      additionalServices: [],
      images: [],
    }),
    []
  );

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: yup.object().shape(validationRules),
    validateOnChange: true,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  useEffect(() => {
    if (state.user) {
      formik.setFieldValue("firstName", state.user.first_name);
      formik.setFieldValue("lastName", state.user.last_name);
      formik.setFieldValue("phoneNumber", state.user.phoneNumber);
      formik.setFieldValue("address", state.user.address);
      formik.setFieldValue("email", state.user.email);
      formik.setFieldValue("confirmEmail", state.user.email);
    } else {
      formik.setValues(initialFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  /* MediaQuerys */
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("md"));

  let gridStyle;
  if (isSm) {
    gridStyle = { width: "100%" };
  } else if (isMd) {
    gridStyle = { maxWidth: "80%", width: "100%" };
  } else {
    gridStyle = { maxWidth: "60%", width: "100%" };
  }

  return (
    <Container maxWidth={"xl"}>
      <Stepper activeStep={activeStep} orientation={isSm ? "vertical" : "horizontal"}>
        {steps.map((step, index) => (
          <Step key={index} className={index === activeStep ? "activeStep" : ""} ref={steps[index].ref}>
            <StepLabel>{step.title}</StepLabel>
            {isSm && activeStep === index && (
              <FormFields activeStep={activeStep} setActiveStep={setActiveStep} gridStyle={gridStyle} formik={formik} sendMail={sendMail} isLoading={isLoading} />
            )}
          </Step>
        ))}
      </Stepper>
      {!isSm && <FormFields activeStep={activeStep} setActiveStep={setActiveStep} gridStyle={gridStyle} formik={formik} sendMail={sendMail} isLoading={isLoading} />}
    </Container>
  );
};

const FormFields = ({ activeStep, setActiveStep, gridStyle, formik, sendMail, isLoading }) => {
  const theme = useTheme();

  const handleNext = () => {
    const currentStep = steps[activeStep];
    const fieldsToValidate = currentStep.fields.map((field) => field.name);

    // Marcar todos los campos como "tocados"
    fieldsToValidate.forEach((field) => {
      formik.setFieldTouched(field, true);
    });

    formik.validateForm().then((errors) => {
      const stepErrors = {};
      fieldsToValidate.forEach((field) => {
        if (errors[field]) {
          stepErrors[field] = errors[field];
        }
      });

      if (Object.keys(stepErrors).length === 0) {
        // Si estamos en el último paso, envía el formulario
        if (activeStep === steps.length - 1) {
          const formattedValues = {
            ...formik.values,
            preferredTime: dayjs(formik.values.preferredTime).format("h:mm A"),
          };
          formik.setValues(formattedValues); // Actualizar los valores formateados en formik
          formik.handleSubmit();
        } else {
          setActiveStep((prevStep) => prevStep + 1);
          currentStep.ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      } else {
        formik.setErrors(stepErrors);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // En el componente donde estás usando los checkboxes
  const handleChangeCheckbox = (name, option) => {
    const currentValue = formik.values[name] || [];
    if (currentValue.includes(option)) {
      // Si la opción actual ya está en el arreglo, la quitamos
      const nextValue = currentValue.filter((item) => item !== option);
      formik.setFieldValue(name, nextValue);
    } else {
      // Si la opción actual no está en el arreglo, la agregamos
      const nextValue = [...currentValue, option];
      formik.setFieldValue(name, nextValue);
    }
  };

  return (
    <FormikProvider value={formik}>
      <form className="stepform_container">
        {sendMail ? (
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" my={10}>
            <Typography variant="h5" color="primary.contrastText" mb={3}>
              Thank you for submitting the form!
            </Typography>
            <img className="sendFormImg" src={sendForm} alt="Send Form" />
          </Box>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            {steps[activeStep].fields.map((field, index) => (
              <Grid item xs={12} key={index} style={{ textAlign: "center" }}>
                <Box>
                  {field.type !== "text" && field.type !== "textarea" && field.type !== "tel" && field.type !== "email" && field.type !== "time" && (
                    <Box
                      mt={4}
                      mb={2}
                      sx={{
                        display: "inline-block", // Hace que la caja se ajuste al contenido
                        backgroundColor: alpha(theme.palette.primary.lightplus, 0.8),
                        padding: "0 10px", // Padding horizontal para darle espacio extra al texto
                        backdropFilter: "blur(1px)", // Aplica el efecto de desenfoque
                        borderRadius: 5,
                      }}
                    >
                      <Typography className={styles.stepform_label} color="primary.dark">
                        {`${field.label}`}
                      </Typography>
                    </Box>
                  )}
                  {/* CHECKBOX */}
                  {field.type === "checkbox" ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          maxWidth: "60%",
                          width: "100%",
                          /* Bloque de validacion */
                          border: formik.touched[field.name] && formik.errors[field.name] ? `1px solid ${theme.palette.error.main}` : "none",
                          borderRadius: "5rem",
                          padding: "10px",
                        }}
                      >
                        {field.options.map((option, index) => (
                          <Grid item xs="auto" key={index}>
                            <Checkbox
                              type="checkbox"
                              name={field.name}
                              option={option}
                              checked={Array.isArray(formik.values[field.name] || []) && formik.values[field.name].includes(option)}
                              onChange={() => handleChangeCheckbox(field.name, option)}
                            />
                          </Grid>
                        ))}
                        {/* Bloque de validacion */}
                        <Grid item xs={12}>
                          {formik.touched[field.name] && formik.errors[field.name] ? (
                            <Box
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Typography color={"error.main"}>{formik.errors[field.name]}</Typography>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  ) : /* TOGGLE BUTTOM */
                  field.type === "radio" ? (
                    field.options.includes("yes") && field.options.includes("no") ? (
                      <ToggleButton name={field.name} />
                    ) : (
                      /* RADIO BUTTON */
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "30px",
                        }}
                      >
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            maxWidth: "60%",
                            width: "100%",
                            /* Bloque de validacion */
                            border: formik.touched[field.name] && formik.errors[field.name] ? `1px solid ${theme.palette.error.main}` : "none",
                            borderRadius: "5rem",
                            padding: "10px",
                          }}
                        >
                          {field.options.map((option, index) => (
                            <Grid item xs="auto" key={index} display="flex" justifyContent="center" alignItems="center" mr={3}>
                              <RadioButton option={option} name={field.name} value={formik.values[field.name]} onChange={(value) => formik.setFieldValue(field.name, value)} />
                            </Grid>
                          ))}
                          {/* Bloque de validacion */}
                          <Grid item xs={12}>
                            {formik.touched[field.name] && formik.errors[field.name] ? (
                              <Box className="errorMsgRadioButtom">
                                <Typography color={"error.main"}>{formik.errors[field.name]}</Typography>
                              </Box>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  ) : /* TEXTAREA */
                  field.type === "textarea" ? (
                    <TextField
                      name={field.name}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      id="outlined-multiline-static"
                      label={field.label}
                      multiline
                      rows={4}
                      InputProps={{
                        style: {
                          color: "white", // Cambiar el color del texto del input
                        },
                      }}
                      sx={{
                        color: "white", // Cambia el color del texto del input
                        mt: 5,
                        "@media (max-width: 960px)": {
                          width: "90%", // Estilos para resoluciones de pantalla más grandes que 600px
                        },
                        "@media (min-width: 960px)": {
                          width: "60%", // Estilos para resoluciones de pantalla más grandes que 960px
                        },
                      }}
                    />
                  ) : /* INPUT TEXT */
                  field.type === "number" ? (
                    <Grid item justifyContent="center" alignItems="center">
                      <Box display="flex" justifyContent="center">
                        <NumberInput name={field.name} onChange={formik.handleChange} value={formik.values[field.name]} />
                      </Box>
                      {formik.touched[field.name] && formik.errors[field.name] ? (
                        <Box
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Typography color={"error.main"}>{formik.errors[field.name]}</Typography>
                        </Box>
                      ) : null}
                    </Grid>
                  ) : field.type === "date" ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "& > :not(style)": { m: 1 },
                        }}
                        mt={2}
                      >
                        <Box
                          sx={{
                            backgroundColor: alpha(theme.palette.primary.lightplus, 0.8), // Aquí puedes ajustar el color de fondo que prefieras
                            borderRadius: 5,
                            backdropFilter: "blur(5px)", // Aplica el efecto de desenfoque
                          }}
                        >
                          <DateCalendar
                            label={field.label}
                            value={formik.values[field.name] ? dayjs(formik.values[field.name]) : null}
                            onChange={(newValue) => {
                              formik.setFieldValue(field.name, dayjs(newValue).format("MMM D, YYYY"));
                            }}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  ) : field.type === "time" ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "& > :not(style)": { m: 1 },
                        }}
                        mt={-4}
                      >
                        <TimePicker
                          label={field.label}
                          value={formik.values[field.name] ? dayjs(formik.values[field.name]) : null}
                          onChange={(newValue) => {
                            formik.setFieldValue(field.name, dayjs(newValue).format());
                          }}
                          sx={{
                            width: "150px",
                            color: "primary.contrastText",
                          }}
                          className="TimePickerCustom"
                        />
                      </Box>
                    </LocalizationProvider>
                  ) : field.type === "file" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <UploadButton name={field.name} value={formik.values[field.name]} onChange={formik.setFieldValue} />

                      <Grid container spacing={2} style={gridStyle}>
                        {formik.values.images.map((image, index) => (
                          <Grid item xs={12} md={6} key={index}>
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "primary.light",
                                borderRadius: 1,
                                borderStyle: "dashed",
                                borderColor: "primary.main",
                                p: 1,
                                height: 50,
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img width={"35px"} src="assets/icons/acortar.png" alt="" />
                                <Typography
                                  variant="body2"
                                  color={"grey.300"}
                                  sx={{
                                    ml: 1,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {image.name}
                                </Typography>
                              </div>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    const updatedImages = formik.values.images.filter((_, i) => i !== index);
                                    formik.setFieldValue("images", updatedImages);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" color="warning" />
                                </IconButton>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ) : (
                    <Grid item justifyContent="center" alignItems="center">
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1 },
                        }}
                        mt={2}
                      >
                        <TextField
                          InputProps={
                            field.type === "tel"
                              ? {
                                  inputComponent: TextMaskCustom,
                                }
                              : {}
                          }
                          name={field.name}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id={`field-${field.name}`}
                          label={field.label}
                          type={field.type}
                          fullWidth
                          error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                          helperText={formik.touched[field.name] && formik.errors[field.name]}
                          margin="normal"
                          sx={{
                            color: "white",
                            width: "80%",
                            maxWidth: "300px",
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Grid>
            ))}
            <Grid container my={4} sx={{ justifyContent: "center", width: "100%", maxWidth: "300px" }}>
              <Grid item md={6} sm={12}>
                <Button disabled={activeStep === 0 || isLoading} onClick={handleBack} sx={{ px: 6, py: 1.5, width: "100%" }}>
                  Back
                </Button>
              </Grid>
              <Grid item md={6} sm={12}>
                <Button variant="contained" color="primary" disabled={isLoading} onClick={handleNext} sx={{ px: 6, py: 1.5, width: "100%" }}>
                  {isLoading ? <CircularProgress size={24} /> : activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </FormikProvider>
  );
};

export default StepForm;
