/* contexts/ReviewsContext.js */
import React, { useReducer, useEffect } from "react";
import { getApi } from "services/api";

const ReviewsContext = React.createContext();

const initialState = {
  reviewsData: [],
};

function reviewsReducer(state, action) {
  switch (action.type) {
    case "addReview":
      const newReview = { ...action.review, rating: parseInt(action.review.rating) }; // Convertir el valor de rating a un número
      return { ...state, reviewsData: [...state.reviewsData, newReview] };
    case "setReviews":
      return { ...state, reviewsData: action.reviews };
    default:
      throw new Error();
  }
}

function ReviewsProvider({ children }) {
  const [state, dispatch] = useReducer(reviewsReducer, initialState);

  useEffect(() => {
    getApi("/reviews").then((response) => {
      if (Array.isArray(response.data.reviews)) {
        dispatch({ type: "setReviews", reviews: response.data.reviews });
      } else {
        console.error("API response is not an array:", response.data);
      }
    });
  }, []);

  const value = { state, dispatch };

  return <ReviewsContext.Provider value={value}>{children}</ReviewsContext.Provider>;
}

export { ReviewsProvider, ReviewsContext };
