import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  // Aquí puedes personalizar el tema según tus necesidades
  palette: {
    primary: {
      main: "#094751", // Color primario
      light: "#2f3342",
      lightplus: "#9ba9da",
      dark: "#000713",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00aaff",
      light: "#189fb2",
    },
    third: {
      main: "#fdd842",
    },
    background: {
      main: "#000713",
      light: "#ccccc",
    },
    error: {
      main: "#FF8097",
    },
  },
  margins: {
    small: "8px",
    medium: "16px",
    large: "130px",
  },
  typography: {
    fontFamily: "Karla, sans-serif", // Agrega las fuentes secundarias como fallback
  },
  transitions: {
    create: () => "all 0.3s ease", // Definir la duración y la función de transición
  },
});

/* styleOverrides with color palette */
theme = createTheme({
  ...theme,
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#ffffff", // Cambia el color aquí
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          "&.MuiStepper-vertical": {
            width: "80%",
            margin: "0 auto",
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.light,
          padding: "16px", // Cambia el padding aquí
          borderRadius: "30px",
          "&.activeStep": {
            backgroundColor: theme.palette.primary.main,
          },
          "&.Mui-completed": {
            backgroundColor: theme.palette.primary.main, // Cambia a tu color deseado para los steps completados
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: theme.palette.primary.main, // Color para los labels inactivos
          fontWeight: "600",
          "&.Mui-active": {
            color: theme.palette.primary.contrastText, // Color para los labels activos
            fontWeight: "600",
          },
          "&.Mui-completed": {
            color: theme.palette.success.main, // Color para los labels completados
            fontWeight: "600",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main, // Cambia el color del icono del paso aquí
          "&.Mui-active": {
            color: theme.palette.secondary.main, // Cambia a tu color deseado
          },
          "&.Mui-completed": {
            color: theme.palette.success.main, // Color para los labels completados
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          "&.Mui-active .MuiStepConnector-line": {
            borderColor: theme.palette.primary.main, // Color para los steps activos
          },
          "&.Mui-completed .MuiStepConnector-line": {
            borderColor: theme.palette.success.main, // Color para los steps completados
          },
          "&.Mui-disabled .MuiStepConnector-line": {
            borderColor: theme.palette.primary.light, // Color para los steps desactivados
          },
          "& .MuiStepConnector-line": {
            borderTopWidth: "6px",
            borderLeftWidth: "6px",
          },
          "&.MuiStepConnector-vertical": {
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light, // Cambiar el color del borde en estado inactivo
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main + " !important",
          },
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(245, 245, 245, 0.5)", // Cambiar el color de fondo cuando está inactivo
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main, // Cambiar el color del borde cuando está enfocado
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            backgroundColor: "rgba(224, 224, 224, 0.5)", // Cambiar el color de fondo cuando está enfocado
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: theme.palette.primary.dark, // Cambiar el color del texto cuando está enfocado
          },
          "& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-input:not(:empty)": {
            color: theme.palette.primary.dark, // Cambiar el color del texto cuando contiene valor
          },
          "& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-input:not(:placeholder-shown)": {
            color: theme.palette.primary.dark, // Cambiar el color del texto cuando contiene valor o texto ingresado
          },
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error,
          },
          "& .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.error,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.dark, // Cambiar el color del texto del label,
          fontWeight: "800",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light, // Cambiar el color del borde en estado inactivo
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main + " !important",
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
          // Cambia el color de las flechas
          "& .MuiPickersCalendarHeader-labelContainer .MuiButtonBase-root, .MuiPickersArrowSwitcher-root .MuiButtonBase-root": {
            color: theme.palette.primary.dark,
          },
          // Aquí estamos seleccionando directamente los nombres de los días de la semana
          "& .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel": {
            color: theme.palette.primary.dark,
          },
        },
      },
    },

    MuiPickersDay: {
      // Aquí estamos personalizando los días del calendario
      styleOverrides: {
        root: {
          color: theme.palette.primary.main, // Esto cambiará el color de los días no seleccionados
          fontWeight: "500",
          // Selecciona el día actual dentro de .root
          "&.MuiPickersDay-today": {
            border: `2px solid ${theme.palette.primary.contrastText}`,
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
          // Selecciona el día seleccionado dentro de .root
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            // Aquí se aplica el estilo cuando el día seleccionado está en focus
            "&.Mui-focused": {
              backgroundColor: theme.palette.primary.main,
            },
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.contrastText,
          },
          fontWeight: "700",
        },
        contained: {
          backgroundColor: theme.palette.primary,
          color: "white",
          transition: "border-color 0.3s",
          boxSizing: "border-box", // Agrega esta propiedad para mantener el tamaño total constante
          "&:hover": {
            border: `1px solid ${theme.palette.primary.contrastText}`,
            fontWeight: "700",
            color: "white",
          },
        },
        outlined: {
          borderColor: "red",
          color: "red",
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.contrastText,
          },
        },
        text: {
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.contrastText,
          },
        },
      },
    },
    /*     MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText, // O el color que prefieras
        },
      },
    }, */
  },
});

export default theme;
