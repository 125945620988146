// About.js
import React from "react";
import { Container, Typography, Button, Grid, Box, List, ListItem, ListItemIcon, ListItemText, Breadcrumbs } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import bannerAboutImg from "./../assets/banner_about.jpg"; // Asegúrate de que la ruta sea correcta
import plumero from "./../assets/plumero.png";
import cubeta from "./../assets/cubeta.png";
import tornEdgeImg from "./../assets/papel_rasgado.png";
import parallaxBannerImg from "./../assets/parallax_banner.jpg";
import { Link as RouterLink } from "react-router-dom";

const About = () => {
  const story =
    "Founded in the heart of a small but vibrant community, Clean American Houses started out \
    as a family venture in 2017. Our founder, Glorys Romero, began with a vacuum cleaner and \
    a mop but harbored a gigantic ambition. Working house to house, the company's \
    reputation grew for reliability, attention to detail, and the personal touch they left in every \
    home. Over the years, we have expanded our team and our services, always maintaining \
    the values that led to our success: integrity, diligence, and a deep respect for our clients' homes.";
  const storyTitle = "Our Story";

  const visionPoints = [
    "Sustainable Innovation: Advocate for sustainable cleaning practices, integrating eco-friendly products that protect both our clients and the environment.",
    "Exceptional Service: Continue to raise the standard of home cleaning, providing a service that goes beyond expectations, ensuring every corner of each home sparkles.",
    "Expansion and Education: Broaden our reach to more communities across the country, educating homeowners on the importance of a healthy, clean living space.",
    "Advanced Technology: Incorporate the latest technology in our services to offer more efficient and thorough cleanings, assuring peace of mind for our clients.",
    "Community Commitment: Give back to the communities we serve, engaging in local initiatives and supporting charitable causes.",
  ];
  const visionTitle = "Our Vision";

  const missionText =
    "Our mission is to deliver top-notch cleaning services that enhance the comfort and well-being of our clients. We strive to exceed expectations and provide impeccable environments, fostering a sense of purity and clarity in every space we touch.";

  const missionTitle = "Our Mission";

  const valuesPoints = [
    "Quality and Excellence: We hold ourselves to the highest standards of cleaning and customer service.",
    "Reliability and Trust: Our clients rely on us for our dependability and trustworthiness in every job we undertake.",
    "Professionalism: Every team member is trained to be professional, efficient, and courteous in all aspects of our service.",
    "Innovation: We are constantly seeking out new methods and products to improve our services and impact on the environment.",
    "Customer Focus: We listen to our clients' needs and tailor our services to meet their individual preferences.",
  ];
  const valuesTitle = "Our Values";

  return (
    <>
      <Banner />
      <StyledHeader title="ABOUT US" subtitle="Keeping Your Spaces Spotless Since 2017" />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Grid container spacing={5} alignItems="flex-start" justifyContent="center">
          <Grid item xs={12} md={5} color={"primary.contrastText"}>
            <Typography variant="h4" gutterBottom>
              {storyTitle}
            </Typography>
            <Typography paragraph variant="h6" sx={{ fontWeight: 200 }}>
              {story}
            </Typography>
            {/* Asegúrate de que el botón 'Learn More' esté alineado o movido según sea necesario */}
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex", // Utiliza flexbox para centrar la imagen
              justifyContent: "center", // Centra horizontalmente la imagen en el contenedor
              alignItems: "center", // Centra verticalmente la imagen en el contenedor
              height: { xs: "auto", sm: 300 }, // Altura automática en móviles y 300px en dispositivos pequeños en adelante
            }}
          >
            <Box
              component="img"
              src={plumero}
              alt="Dynamic Element"
              sx={{
                maxWidth: "100%", // La imagen ocupa como máximo el 100% del ancho del contenedor
                height: "auto", // La altura de la imagen es automática para mantener la proporción
                width: { xs: "80%", sm: "40%", md: "100%" }, // En móviles usa el 80% del ancho, en dispositivos medianos y mayores el 100%
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} color={"primary.contrastText"}>
            <Typography variant="h4" gutterBottom>
              {missionTitle}
            </Typography>
            <Typography paragraph variant="h6" sx={{ fontWeight: 200 }}>
              {missionText}
            </Typography>
            {/* Asegúrate de que el botón 'Learn More' esté alineado o movido según sea necesario */}
          </Grid>
        </Grid>
      </Container>
      <ParallaxBanner />
      <StyledHeader title="SATISFACTION" subtitle="Our main mission is to guarantee you a 5 star service." />
      <Content title={visionTitle} contentOne={visionPoints} titleTwo={valuesTitle} contentTwo={valuesPoints} img={cubeta} />
    </>
  );
};

const Content = ({ title, contentOne, titleTwo, contentTwo, img }) => {
  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <Grid container spacing={5} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={5} color={"primary.contrastText"}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          {/* Asegúrate de que la lista comience en la misma altura que la historia */}
          <List>
            {contentOne.map((point, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: "primary.lightplus" }} /> {/* Asegúrate de que este color exista en tu tema */}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h6",
                    sx: { fontWeight: 200 }, // Aquí puedes poner los estilos que desees.
                  }}
                  primary={point}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex", // Utiliza flexbox para centrar la imagen
            justifyContent: "center", // Centra horizontalmente la imagen en el contenedor
            alignItems: "center", // Centra verticalmente la imagen en el contenedor
            height: { xs: "auto", sm: 300 }, // Altura automática en móviles y 300px en dispositivos pequeños en adelante
          }}
        >
          <Box
            component="img"
            src={img}
            alt="Dynamic Element"
            sx={{
              maxWidth: "100%", // La imagen ocupa como máximo el 100% del ancho del contenedor
              height: "auto", // La altura de la imagen es automática para mantener la proporción
              width: { xs: "80%", sm: "40%", md: "100%" }, // En móviles usa el 80% del ancho, en dispositivos medianos y mayores el 100%
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} color={"primary.contrastText"}>
          <Typography variant="h4" gutterBottom>
            {titleTwo}
          </Typography>
          {/* Asegúrate de que la lista comience en la misma altura que la historia */}
          <List>
            {contentTwo.map((point, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: "primary.lightplus" }} /> {/* Asegúrate de que este color exista en tu tema */}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h6",
                    sx: { fontWeight: 200 }, // Aquí puedes poner los estilos que desees.
                  }}
                  primary={point}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

const ParallaxBanner = () => {
  return (
    <Box
      sx={{
        width: "100%", // Ocupa todo el ancho
        height: "50vh", // 40% del alto de la ventana
        backgroundImage: `url(${parallaxBannerImg})`,
        backgroundAttachment: "fixed", // Crea el efecto parallax
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // Asegura que la imagen de fondo cubra el área del banner
      }}
    >
      {/* Puedes agregar contenido aquí si es necesario */}
    </Box>
  );
};

const StyledHeader = ({ title, subtitle }) => {
  return (
    <Box sx={{ textAlign: "center", py: 5, position: "relative" }}>
      <Container maxWidth="lg">
        {/* Contenedor para la línea vertical */}
        <Box
          sx={{
            height: 100, // Ajusta la altura según lo que necesites
            width: "4px", // Ancho de la línea
            bgcolor: "primary.main", // Color de fondo (la línea en sí)
            margin: "auto", // Centra horizontalmente la línea
            my: 2, // Margen superior e inferior
          }}
        />
        <Typography variant="h4" display="block" gutterBottom color={"primary.contrastText"} sx={{ letterSpacing: "6px" }}>
          {title}
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }} color={"primary.contrastText"}>
          {subtitle}
        </Typography>
      </Container>
    </Box>
  );
};

const Banner = () => {
  return (
    <Box
      sx={{
        width: "100%", // Ocupa todo el ancho
        height: "40vh", // Ocupa el 40% del alto de la ventana
        background: `url(${bannerAboutImg}) no-repeat center center`, // Usa la imagen importada
        backgroundSize: "cover", // La imagen de fondo cubre todo el espacio
        backgroundAttachment: "fixed", // Fija el fondo para crear el efecto parallax
        display: "flex", // Usa Flexbox para centrar el contenido
        flexDirection: "column", // Orientación de los items en columna
        justifyContent: "center", // Centra verticalmente
        alignItems: "center", // Centra horizontalmente
        color: "white", // Color del texto
        textTransform: "uppercase", // Estilo de texto en mayúsculas si se desea
      }}
    >
      {/* <Typography variant="h3" component="h1" sx={{ fontWeight: "bold" }}>
        About Us
      </Typography> */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: "white" }}>
        <RouterLink to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Typography component="span" variant="subtitle2" color="inherit">
            Home
          </Typography>
        </RouterLink>
        {/* No necesitas cambiar el Typography para 'About Us' ya que no es un enlace */}
        <Typography color="primary.lightplus">About Us</Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default About;
