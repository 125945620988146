import React from "react";
import { Box, Typography } from "@mui/material";
import ScrollIndicator from "./elements/ScrollIndicator";
import "./../styles/banner.css";
import { styled } from "@mui/material/styles";

const Banner = ({ Image, title, subtitle, height, paddingTop }) => {
  const StyledBanner = styled("section")(({ theme }) => ({
    // Estilos comunes para todas las resoluciones

    [theme.breakpoints.up("sm")]: {
      // Estilos específicos para pantallas mayor a 600px de ancho
      "& .titleBanner": {
        fontSize: "5rem !important",
      },
      "& .descriptionBanner": {
        fontSize: "1.5rem !important",
      },
      "& .home-content.container": {
        paddingTop: paddingTop, // Cambia aquí el valor por defecto si lo necesitas
      },
    },
    [theme.breakpoints.down("sm")]: {
      // Estilos específicos para pantallas menor a 600px de ancho
      "& .titleBanner": {
        fontSize: "2.5rem !important",
      },
      "& .descriptionBanner": {
        fontSize: "1.2rem !important",
      },
      "& .descriptionBannerContainer": {
        maxWidth: "90% !important",
      },
      "& .home-content.container": {
        paddingTop: paddingTop, // Cambia aquí el valor por defecto si lo necesitas
      },
    },
  }));

  return (
    <StyledBanner className="background-container" style={{ height: height + "vh", position: "relative" }}>
      <div className="background-image" style={{ backgroundImage: `url(${Image})` }} />
      <div className="home-content container" style={{ margin: "auto", position: "relative", zIndex: 2 }}>
        <Typography variant="h1" align="center" dangerouslySetInnerHTML={{ __html: title }} className="titleBanner"></Typography>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            mx: "auto",
            maxWidth: "50%",
            pt: 3,
          }}
          className="descriptionBannerContainer"
        >
          <Typography variant="h4" align="center" dangerouslySetInnerHTML={{ __html: subtitle }} className="descriptionBanner"></Typography>
        </Box>
      </div>
      <ScrollIndicator height={height} />
    </StyledBanner>
  );
};

export default Banner;
