import React from "react";
import { Avatar, Card, CardContent, Typography, Box, Rating } from "@mui/material";
import { styled } from "@mui/material/styles";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import useMediaQuery from "@mui/material/useMediaQuery";

const ReviewCard = ({ review }) => {
  const StyledReviewCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    [theme.breakpoints.up("sm")]: {
      width: "400px",
      height: "500px",
      margin: "0 40px",
    },
    [theme.breakpoints.between("xs", "860")]: {
      width: "250px",
      height: "350px",
      margin: "10px 10px",
    },
  }));

  const isSmallScreen = useMediaQuery("(max-width:860px)");
  const isMediumScreen = useMediaQuery("(min-width:861px) and (max-width:1200px)");

  const getMaxLength = () => {
    if (isSmallScreen) return 200; // Límite de caracteres para pantallas pequeñas
    if (isMediumScreen) return 800; // Límite de caracteres para pantallas medianas
    return 800; // Límite de caracteres para pantallas grandes
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <StyledReviewCard className="ReviewCard" sx={{ borderRadius: 5 }}>
      <QuoteIcon style={{ fontSize: 80 }} sx={{ color: "primary.main" }} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {truncateText(review.comment, getMaxLength())} {/* Ajusta el límite de caracteres */}
        </Typography>
        <Box display="flex" alignItems="center" mt={2}>
          <Avatar alt={review.name} src={review.image} />
          <Box ml={1}>
            <Typography variant="subtitle2" component="div">
              {review.first_name} {review.last_name}
            </Typography>
            <Rating
              name="read-only"
              value={parseInt(review.rating)}
              readOnly
              sx={{ color: "secondary.main" }}
            />
            <Typography variant="body2" color="text.secondary" ml={2}>
              {review.comment_date} {/* Mostrar la fecha directamente */}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </StyledReviewCard>
  );
};

export default ReviewCard;
