import React, { useRef } from "react";
import { Button, Box, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTheme } from "@mui/material/styles";

export default function UploadButton({ name, value, onChange }) {
  const fileInputRef = useRef();

  const handleFileSelect = (event) => {
    event.stopPropagation();
    const files = Array.from(event.target.files).map((file) => ({
      name: file.name,
      file,
    }));
    onChange(name, files); // Aquí estamos pasando la lista de archivos directamente
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      let files = [];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === "file") {
          const file = event.dataTransfer.items[i].getAsFile();
          files.push({
            name: file.name,
            file,
          });
        }
      }
      // Aquí estamos pasando la lista de archivos directamente
      onChange(name, files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "80%",
        maxWidth: "300px",
        height: 200,
        borderColor: "primary.main",
        borderStyle: "dashed",
        backgroundColor: "primary.light",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
      }}
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
    >
      <CloudUploadIcon style={{ fontSize: 60, color: theme.palette.primary.contrastText }} />
      <Typography color={"primary.contrastText"}>Drag & Drop to upload</Typography>
      <Button
        variant="contained" // Esto hace que el botón tenga un fondo de color
        color="primary"
        onClick={handleButtonClick}
        sx={{
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "white",
            color: "primary.contrastText",
          },
          transition: "all 0.3s", // Esto añade la animación
        }}
      >
        Select File
      </Button>

      <input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={handleFileSelect} multiple />
    </Box>
  );
}
