// Services.js
import React, { useEffect } from "react";
import { Button, Box, Grid, Container, Typography } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import KitchenIcon from "@mui/icons-material/Kitchen";
import BathtubIcon from "@mui/icons-material/Bathtub";
import BedroomIcon from "@mui/icons-material/KingBed";
import HotelIcon from "@mui/icons-material/Nature";
import LivingIcon from "@mui/icons-material/Weekend";
import DiningIcon from "@mui/icons-material/Restaurant";
import LaundryIcon from "@mui/icons-material/LocalLaundryService";
import GarageIcon from "@mui/icons-material/DriveEta";

import servicesImage from "../assets/House-Cleaner-Services.jpg";
import Banner from "./Banner";
import StepForm from "./StepForm";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

import BackgroundImage from "./../assets/form.jpg"; // Asegúrate de reemplazar la ruta con la ruta correcta a tu imagen.

const Services = () => {
  // Dentro de tu componente
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.fromReserveButton) {
      const reservacionTop = reservacionRef.current.getBoundingClientRect().top;
      const offset = 130; // Ajusta este valor según tus necesidades
      window.scrollTo({
        top: reservacionTop - offset + window.pageYOffset,
        behavior: "smooth",
      });
    }
  }, []);
  // Array de datos de ejemplo para las cajas
  const boxData = [
    {
      title: "Airbnb Cleaning",
      description: "Comprehensive cleaning services tailored for Airbnb hosts, ensuring each guest enjoys a spotless and welcoming stay, integrating pleasant aromas, impeccable order, and thorough cleanliness.",
      icon: <HotelIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Living Room",
      description: "Thorough cleaning and tidying of your living room, making it a relaxing space for everyone.",
      icon: <LivingIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Kitchen",
      description: "Deep cleaning of the kitchen area, ensuring hygiene and order in all corners.",
      icon: <KitchenIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Bathroom",
      description: "Full bathroom cleaning, including tiles, sink, bathtub, toilet, and all surfaces.",
      icon: <BathtubIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Bedroom",
      description: "Detailed cleaning of bedrooms, including bed making, dusting, and vacuuming.",
      icon: <BedroomIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Whole House",
      description: "Complete cleaning of the entire house, covering all rooms and areas.",
      icon: <HomeIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Dining Room",
      description: "Keeping your dining room clean and ready for any meal, any time.",
      icon: <DiningIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Laundry Room",
      description: "Managing all your laundry needs, from washing to folding clothes.",
      icon: <LaundryIcon style={{ fontSize: 50 }} />,
    },
    {
      title: "Garage",
      description: "Organizing and cleaning your garage will make it a functional space.",
      icon: <GarageIcon style={{ fontSize: 50 }} />,
    },
  ];

  const reservacionRef = useRef(null);

  return (
    <>
      <Banner Image={servicesImage} title={"Services"} subtitle={"These are some of our services:"} height={100} paddingTop={"30vh"} />
      <Box sx={{ position: "relative", zIndex: 1 }} pt={15} pb={15}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} mb={8}>
              <Typography variant="h3" fontWeight="bold" color={"primary.contrastText"}>
              Everything you need in one place
            </Typography>
            <Typography variant="h6" color={"primary.contrastText"} mt={2}>
              You can choose any place in your home and apply for the service.
            </Typography>
            <Typography variant="h6" color={"primary.contrastText"} mt={2}>
              Now offering specialized cleaning services for airbnb hosts to ensure your guests have an exceptional stay.
            </Typography>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" alignItems="center" container mb={8}>
              <Button
                variant="contained"
                color="primary"
                sx={{ py: 2, px: 4 }}
                onClick={() => {
                  const reservacionTop = reservacionRef.current.getBoundingClientRect().top;
                  const offset = 130; // Ajusta este valor según tus necesidades
                  window.scrollTo({
                    top: reservacionTop - offset + window.pageYOffset,
                    behavior: "smooth",
                  });
                }}
              >
                QUOTE
              </Button>
            </Grid>
            {boxData.map((data, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} mb={2}>
                <Grid container style={{ height: "100%" }} display="flex" alignItems="stretch">
                  <Box
                    bgcolor={"primary.main"}
                    sx={{
                      borderRadius: 4,
                      mx: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                    color={"primary.contrastText"}
                  >
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={2} color={"primary.contrastText"}>
                        <Box p={5}>{data.icon}</Box>
                      </Grid>
                      <Grid item xs={10}>
                        <Box p={5}>
                          <Typography variant="h6" textAlign="center">
                            {data.title}
                          </Typography>
                          <Typography variant="body1" textAlign="center">
                            {data.description}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box
        ref={reservacionRef}
        sx={{
          position: "relative",
          pt: 2,
          pb: 10,
        }}
      >
        <div className="backgroundDiv" style={{ backgroundImage: `url(${BackgroundImage})` }} />
        <StepForm />
      </Box>
    </>
  );
};

export default Services;
