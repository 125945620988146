import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "./../assets/carousel1.jpg";
import Image2 from "./../assets/carousel2.jpg";
import Image3 from "./../assets/carousel3.jpeg";
import "./../styles/carousel.css"; // Archivo CSS para estilos personalizados

const carouselData = [
  {
    image: Image1,
    text: "Professional house cleaning services",
  },
  {
    image: Image2,
    text: "Reliable and trustworthy cleaners",
  },
  {
    image: Image3,
    text: "Efficient and detail-oriented housekeeping",
  },
];

const Carousel = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "0px",
    adaptiveHeight: false,
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {carouselData.map((item, index) => (
          <div key={index}>
            <div className="image-wrapper">
              <div className="image-crop">
                <img src={item.image} alt={`Description ${index + 1}`} className="carousel-image" />
              </div>
              <div className="carousel-text">{item.text}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
