// LoginForm.js
import React, { useEffect, useRef, useState, useLayoutEffect, useContext } from "react";
import { postApi } from "services/api";
import styles from "styles/LoginForm.module.scss";
import { Typography, IconButton, Grid } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { AuthContext } from "contexts/AuthContext";
import { IMaskInput } from "react-imask";

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref} // Asegúrate de pasar el ref al componente IMaskInput
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const LoginForm = ({ onClose }) => {
  const { dispatch } = useContext(AuthContext);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const panelOneRef = useRef(null);
  const panelTwoRef = useRef(null);
  const [panelOneHeight, setPanelOneHeight] = useState(0);
  const [panelTwoHeight, setPanelTwoHeight] = useState(0);
  const [isPanelTwoActive, setPanelTwoActive] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [registerFormData, setRegisterFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    phoneNumber: "",
    address: "",
    password: "",
    cpassword: "",
    email: "",
  });

  const initialFormRegister = {
    username: "",
    first_name: "",
    last_name: "",
    phoneNumber: "",
    address: "",
    password: "",
    cpassword: "",
    email: "",
  };

  useLayoutEffect(() => {
    if (panelOneRef.current) {
      setPanelOneHeight(panelOneRef.current.offsetHeight);
    }
    if (panelTwoRef.current) {
      setPanelTwoHeight(panelTwoRef.current.offsetHeight);
    }
  }, []);

  const handlePanelTwoClick = () => {
    if (panelTwoRef.current) {
      setPanelTwoActive(true);
    }
  };

  const handleFormToggleClick = () => {
    setPanelTwoActive(false);
  };

  const [springProps, api] = useSpring(() => ({
    height: isPanelTwoActive ? panelTwoHeight : panelOneHeight,
    config: { duration: 200 },
  }));

  useEffect(() => {
    api.start({
      from: { height: isPanelTwoActive ? panelOneHeight : panelTwoHeight },
      to: { height: isPanelTwoActive ? panelTwoHeight : panelOneHeight },
      config: { duration: 200 },
    });
  }, [isPanelTwoActive, api, panelOneHeight, panelTwoHeight]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const errors = {};

    //Agregar errores al array
    !loginFormData.email && (errors.email = "email is required");
    !loginFormData.password && (errors.password = "Password is required");

    if (Object.keys(errors).length === 0) {
      const response = await postApi(formData, "/login");

      switch (response.status) {
        case "success":
          // Aquí inicias la sesión y guardas el usuario en el estado
          dispatch({ type: "LOGIN", payload: response.data.user });
          Toast.fire({
            icon: "success",
            title: "You are successfully logged in.",
          });
          onClose();
          break;
        case "error":
          let errorMessage = response.message;
          let fieldErrorMessages = Object.values(response.errors).flat().join("<br>");
          let htmlMessage = fieldErrorMessages.length > 0 ? `<strong>${errorMessage}:</strong> <br> ${fieldErrorMessages}` : `<strong>${errorMessage}</strong>`;

          Toast.fire({
            icon: "error",
            html: htmlMessage,
          });
          break;
        default:
          Toast.fire({
            icon: "error",
            title: "Error while sending the form, please contact technical support.",
          });
          break;
      }
    } else {
      //se asigna el array de errores al estado
      setFormErrors(errors);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const errors = {};
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //Agregar errores al array
    !registerFormData.username && (errors.username = "Username is required");
    !registerFormData.first_name && (errors.first_name = "First Name is required");
    !registerFormData.last_name && (errors.last_name = "Last Name is required");
    !registerFormData.phoneNumber && (errors.phoneNumber = "Phone Number is required");
    !registerFormData.address && (errors.address = "Address is required");
    !registerFormData.password && (errors.password = "Password is required");
    !registerFormData.cpassword && (errors.cpassword = "Confirm Password is required");
    registerFormData.cpassword !== registerFormData.password && (errors.cpassword = "Passwords must be the same");
    !registerFormData.email && (errors.email = "Email is required");
    !emailRegEx.test(registerFormData.email) && (errors.email = "Email is not valid");

    if (Object.keys(errors).length === 0) {
      const response = await postApi(formData, "/register");

      switch (response.status) {
        case "success":
          Toast.fire({
            icon: "success",
            title: "You have successfully registered, please login.",
          });
          setPanelTwoActive(false);
          setRegisterFormData(initialFormRegister);
          break;
        case "error":
          let errorMessage = response.message;
          let fieldErrorMessages = Object.values(response.errors).flat().join("<br>");
          let htmlMessage = fieldErrorMessages.length > 0 ? `<strong>${errorMessage}</strong> <br> ${fieldErrorMessages}` : `<strong>${errorMessage}</strong>`;

          Toast.fire({
            icon: "error",
            html: htmlMessage,
          });
          break;
        default:
          Toast.fire({
            icon: "error",
            title: "Error while sending the form, please contact technical support.",
          });
          break;
      }
    } else {
      //se asigna el array de errores al estado
      setFormErrors(errors);
    }
  };

  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleRegisterInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  return (
    <Grid container spacing={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <animated.div className={styles.form} style={springProps} sx={{ position: "relative" }}>
          <IconButton aria-label="close" onClick={onClose} sx={{ top: 20, left: 45, color: "error.main", position: "absolute", padding: 0 }} className={styles.closeModal}>
            <CloseIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>
          <IconButton
            aria-label="close"
            className={`${styles.form_toggle} ${isPanelTwoActive ? styles.visible : ""}`}
            onClick={handleFormToggleClick}
            sx={{ top: 60, right: 60, color: "error.main", position: "absolute" }}
          >
            <CloseIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>

          {/* Login form */}
          <div className={`${styles.form_panel} ${styles.one} ${isPanelTwoActive ? styles.hidden : ""}`} ref={panelOneRef}>
            <div className={styles.form_header}>
              <Typography variant="h1">Account Login</Typography>
            </div>
            <div className={styles.form_content}>
              <form onSubmit={handleLoginSubmit} sx={{ mb: 10 }} noValidate>
                <div className={styles.form_group} sx={{ my: 5 }}>
                  <Typography component="label" htmlFor="username" variant="body1">
                    Email
                  </Typography>
                  <input
                    id="logusername"
                    type="text"
                    name="email"
                    value={loginFormData.email}
                    onChange={handleLoginInputChange}
                    className={formErrors.email ? styles.inputError : ""}
                  />
                  {formErrors.email && <div className={styles.errorText}>{formErrors.email}</div>}
                </div>
                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="password" variant="body1">
                    Password
                  </Typography>
                  <input
                    id="logpassword"
                    type="password"
                    name="password"
                    value={loginFormData.password}
                    onChange={handleLoginInputChange}
                    className={formErrors.password ? styles.inputError : ""}
                  />
                  {formErrors.password && <div className={styles.errorText}>{formErrors.password}</div>}
                </div>
                <div className={styles.form_group}>
                  <label className={styles.form_remember}>
                    <input type="checkbox" />
                    <Typography variant="body1">Remember Me</Typography>
                  </label>
                  <a className={styles.form_recovery} href="#top">
                    <Typography variant="body1">Forgot Password?</Typography>
                  </a>
                </div>
                <div className={styles.form_group}>
                  <button type="submit">Log In</button>
                </div>
              </form>
            </div>
          </div>
          {/* Register form */}
          <div className={`${styles.form_panel} ${styles.two} ${isPanelTwoActive ? styles.active : ""}`} ref={panelTwoRef} onClick={handlePanelTwoClick}>
            <div className={styles.form_header}>
              <Typography variant="h1">Register Account</Typography>
            </div>
            <div className={styles.form_content}>
              <form onSubmit={handleRegisterSubmit} noValidate>
                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="username" variant="body1">
                    Username
                  </Typography>
                  <input
                    id="username"
                    type="text"
                    name="username"
                    value={registerFormData.username}
                    onChange={handleRegisterInputChange}
                    className={formErrors.username ? styles.inputError : ""}
                  />
                  {formErrors.username && <div className={styles.errorText}>{formErrors.username}</div>}
                </div>
                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="first_name" variant="body1">
                    First Name
                  </Typography>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={registerFormData.first_name}
                    onChange={handleRegisterInputChange}
                    className={formErrors.first_name ? styles.inputError : ""}
                  />
                  {formErrors.first_name && <div className={styles.errorText}>{formErrors.first_name}</div>}
                </div>
                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="last_name" variant="body1">
                    Last Name
                  </Typography>
                  <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={registerFormData.last_name}
                    onChange={handleRegisterInputChange}
                    className={formErrors.last_name ? styles.inputError : ""}
                  />
                  {formErrors.last_name && <div className={styles.errorText}>{formErrors.last_name}</div>}
                </div>

                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="phoneNumber" variant="body1">
                    Phone Number
                  </Typography>
                  <TextMaskCustom
                    id="phoneNumber"
                    name="phoneNumber"
                    value={registerFormData.phoneNumber}
                    onChange={handleRegisterInputChange}
                    className={formErrors.phoneNumber ? styles.inputError : ""}
                  />
                  {formErrors.phoneNumber && <div className={styles.errorText}>{formErrors.phoneNumber}</div>}
                </div>

                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="address" variant="body1">
                    Address
                  </Typography>
                  <input
                    id="address"
                    type="text"
                    name="address"
                    value={registerFormData.address}
                    onChange={handleRegisterInputChange}
                    className={formErrors.address ? styles.inputError : ""}
                  />
                  {formErrors.address && <div className={styles.errorText}>{formErrors.address}</div>}
                </div>

                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="password" variant="body1">
                    Password
                  </Typography>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    value={registerFormData.password}
                    onChange={handleRegisterInputChange}
                    className={formErrors.password ? styles.inputError : ""}
                  />
                  {formErrors.password && <div className={styles.errorText}>{formErrors.password}</div>}
                </div>
                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="cpassword" variant="body1">
                    Confirm Password
                  </Typography>
                  <input
                    id="cpassword"
                    type="password"
                    name="cpassword"
                    value={registerFormData.cpassword}
                    onChange={handleRegisterInputChange}
                    className={formErrors.cpassword ? styles.inputError : ""}
                  />
                  {formErrors.cpassword && <div className={styles.errorText}>{formErrors.cpassword}</div>}
                </div>
                <div className={styles.form_group}>
                  <Typography component="label" htmlFor="email" variant="body1">
                    Email Address
                  </Typography>
                  <input
                    id="regemail"
                    type="email"
                    name="email"
                    value={registerFormData.email}
                    onChange={handleRegisterInputChange}
                    className={formErrors.email ? styles.inputError : ""}
                  />
                  {formErrors.email && <div className={styles.errorText}>{formErrors.email}</div>}
                </div>
                <div className={styles.form_group}>
                  <button type="submit">Register</button>
                </div>
              </form>
            </div>
          </div>
        </animated.div>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
