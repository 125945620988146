import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const CardComp = ({ title, description, icon }) => {
  return (
    <Card
      sx={{
        textAlign: "center",
        padding: "25px 0 25px 0",
        bgcolor: "primary.main",
        color: "white",
        borderRadius: 4,
      }}
    >
      <CardContent>
        {icon}
        <Typography
          sx={{ fontSize: 25 }}
          color="primary.contrastText"
          gutterBottom
          lineHeight={2}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: 15 }}
          color="primary.contrastText"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComp;
