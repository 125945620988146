import { useField } from "formik";
import { IconButton, InputAdornment, OutlinedInput, Box } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
const NumberInput = ({ name, ...props }) => {
  const theme = useTheme();
  const [field, meta, helpers] = useField(name);

  const error = meta.touched && meta.error;

  const handleChange = (e) => {
    const { value } = e.target;
    helpers.setValue(value);
  };

  const handleIncrement = () => {
    helpers.setValue(field.value + 1);
  };

  const handleDecrement = () => {
    helpers.setValue(field.value > 0 ? field.value - 1 : 0);
  };

  return (
    <Box className="number-input">
      <OutlinedInput
        className="input-wrapper"
        type="number"
        inputMode="numeric"
        pattern="\d*"
        value={field.value}
        onChange={handleChange}
        {...props}
        style={
          error
            ? {
                borderColor: theme.palette.error.main,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "25px",
              }
            : {}
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton className="increment-button" onClick={handleIncrement}>
              <Add />
            </IconButton>
          </InputAdornment>
        }
        startAdornment={
          <IconButton className="decrement-button" onClick={handleDecrement}>
            <Remove />
          </IconButton>
        }
      />
    </Box>
  );
};

export default NumberInput;
