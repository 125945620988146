import "./../styles/header.css";
import { NavLink, useLocation } from "react-router-dom";
import logo from "./../assets/logo.png";
import React, { useEffect, useState, useContext } from "react";
import { AppBar, Tab, Tabs, Toolbar, Typography, useMediaQuery, useTheme, Button, Dialog, DialogContent } from "@mui/material";
import DrawerComp from "./DrawerComp";
import { Container } from "@mui/material";
import { alpha } from "@mui/material/styles";
import LoginForm from "./elements/LoginForm";
import Swal from "sweetalert2";
import { AuthContext } from "contexts/AuthContext";
import ScrollToTop from "./elements/ScrollToTop";

const Header = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const { state, dispatch } = useContext(AuthContext);
  // eslint-disable-next-line
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation(); // Obtener la ubicación actual
  const [openModal, setOpenModal] = useState(false);
  //Manejar el SCROLL para cambiar fondo de Header
  const mainPrimaryColor = alpha(theme.palette.primary.main, 0.9);
  const [scrolled, setScrolled] = React.useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 80) {
        // Puedes cambiar este valor dependiendo de cuándo quieres que se active el color de fondo
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pages = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Services",
      path: "/services",
    },
    {
      label: "About Us",
      path: "/aboutus",
    },
    {
      label: "Contact Us",
      path: "/contactus",
    },
    {
      label: "Testimonials",
      path: "/testimonials",
    },
  ];
  // Obtener el índice de la página activa en función de la ubicación actual
  const activePageIndex = pages.findIndex((page) => page.path === location.pathname);

  const handleLoginClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    Toast.fire({
      icon: "success",
      title: "You have successfully closed the session",
    });
  };

  return (
    <AppBar
      sx={{
        background: scrolled ? mainPrimaryColor : "transparent",
        paddingTop: "30px",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <ScrollToTop />
          {isMatch ? (
            <>
              <DrawerComp pages={pages} onOpen={handleLoginClick} />
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  paddingLeft: "25%",
                  textAlign: "end",
                  paddingRight: "5px",
                  fontWeight: "600",
                }}
                className="marca"
              >
                CLEAN AMERICAN HOUSES
              </Typography>
            </>
          ) : (
            <>
              <Tabs textColor="inherit" value={activePageIndex} onChange={(e, value) => setValue(value)} sx={{ margin: "auto" }}>
                {pages.map((page, index) => (
                  <Tab label={page.label} key={index} component={NavLink} to={page.path} />
                ))}
              </Tabs>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: theme.palette.common.white,
                  borderColor: theme.palette.primary.lightplus,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.common.white,
                  },
                }}
                onClick={state.isLoggedIn ? handleLogout : handleLoginClick} // Agrega este atributo onClick
              >
                {state.isLoggedIn ? "Logout" : "Login / Sign Up"}
              </Button>

              {/* <Button sx={{marginLeft: "auto"}} variant='contained'>Login</Button>
                <Button sx={{marginLeft: "10px"}} variant='contained'>SignUp</Button> */}
            </>
          )}
          <img className="logo" alt="logo" src={logo} width={80} height={80} style={{marginLeft: "20px"}}/>
        </Toolbar>
      </Container>
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth PaperProps={{ style: { maxHeight: "100%", overflow: "hidden" } }}>
        <DialogContent dividers style={{ padding: 0 }}>
          <LoginForm onClose={handleCloseModal} />
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default Header;
