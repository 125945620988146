import React from "react";
import "./../styles/home.css"; // Asegúrate de tener un archivo CSS para tu componente Home
import CardComp from "./elements/CardComp";
import { Grid, Typography, Box, Container, Rating, Button } from "@mui/material";

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StarIcon from "@mui/icons-material/Star";
import BusinessIcon from "@mui/icons-material/Business";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AirIcon from "@mui/icons-material/Air";
import SecurityIcon from "@mui/icons-material/Security";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";

import Image1 from "../assets/1.jpeg"; // reemplaza la ruta con la ruta de tu imagen
import Image2 from "../assets/7.JPG"; // reemplaza la ruta con la ruta de tu imagen
import Carousel from "./Carousel";

import bathrooms from "../assets/Bathrooms.JPG";
import kitchen from "../assets/Kitchen.jpg";
import rooms from "../assets/Rooms.JPG";
import livingRoom from "../assets/LivingRoom.jpg";
import interiorAreas from "../assets/interiorAreas.jpg";
import livingSpaces from "../assets/livingSpaces.JPG";

import Video from "./../assets/Profesional.mp4";


import ScrollIndicator from "./elements/ScrollIndicator";

import banerImage from "../assets/baner1.jpg";
import Banner from "./Banner";

import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const cards = [
    {
      title: "Tailored professional cleaning",
      description: "Efficient and personalized",
      icon: <CleaningServicesIcon style={{ fontSize: "60px", padding: "20px 0 20px 0" }} />,
    },
    {
      title: "Excellence in residential cleaning",
      description: "Detailed and reliable",
      icon: <StarIcon style={{ fontSize: "60px", padding: "20px 0 20px 0" }} />,
    },
    {
      title: "Corporate cleaning services",
      description: "Flexible and responsible",
      icon: <BusinessIcon style={{ fontSize: "60px", padding: "20px 0 20px 0" }} />,
    },
  ];

  return (
    <>
      <Carousel />
      <ScrollIndicator style={{ zIndex: "3px" }} />
      <Container maxWidth="xl">
        {/* Cards */}
        <Grid
          container
          spacing={1}
          sx={{
            position: "relative",
            zIndex: 2,
            marginBottom: "130px",
          }}
          className="containerCards"
        >
          {cards.map((card, index) => (
            <Grid key={index} item xs={12} md={4}>
              <CardComp title={card.title} description={card.description} icon={card.icon} />
            </Grid>
          ))}
        </Grid>
        {/* Unmatched Cleaning */}
        <Grid container spacing={1} sx={{ marginBottom: "100px" }}>
          <Grid item xs={12} md={6}>
            <Box className="boxContainer">
              <img className="image1" src={Image1} alt="Imagen 1" />
              <img className="image2" src={Image2} alt="Imagen 2" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 10,
                color: "primary.contrastText",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="boxContainerUnmatched"
            >
              <Typography className="UnmatchedTitle" variant="h2">
                Unmatched Cleaning Service Excellence
              </Typography>
              <Typography className="UnmatchedDescription" variant="body1" sx={{ padding: "20px 0 20px 0" }}>
                Hire the best cleaning service for a spotless and fresh home at all times. Our highly trained team and advanced cleaning methods ensure unparalleled results.
                Experience the highest standard of cleanliness with us and enjoy a clean and healthy environment in your home!
              </Typography>
              <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: "20px", ml: 2 }}>
                <Grid item xs={12} sm={4} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "10px",
                    }}
                  >
                    <AddModeratorIcon color="primary" sx={{ mr: "15px" }} />
                    <Typography variant="body1">Security</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "10px",
                    }}
                  >
                    <CleaningServicesIcon color="primary" sx={{ mr: "15px" }} />
                    <Typography variant="body1">Cleaning</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "10px",
                    }}
                  >
                    <AirIcon color="primary" sx={{ mr: "15px" }} />
                    <Typography variant="body1">Speed</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: "20px" }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      p: "10px",
                      borderRadius: 4,
                    }}
                  >
                    <Button variant="contained" sx={{ py: 2, px: 10, bgcolor: "secondary.main" }} onClick={() => navigate("/services", { state: { fromReserveButton: true } })}>
                      Make your Home Shine!
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Banner
        Image={banerImage}
        title={"Sparkling homes, <br /> happy lives!"}
        subtitle={"Our cleaning company is dedicated to revitalizing your home and transforming it into a place of pure bliss."}
        height={80}
        paddingTop={"8vh"}
      />
      <RatingBlock />
      <SomeServices />
      <VideoBlock />
    </>
  );
};

const RatingBlock = () => {
  const blocks = [
    {
      title: "Security",
      icon: <SecurityIcon style={{ fontSize: 50 }} />,
      rating: 5,
    },
    {
      title: "Cleaning",
      icon: <CleaningServicesIcon style={{ fontSize: 50 }} />,
      rating: 5,
    },
    {
      title: "Certification",
      icon: <BookmarkAddedIcon style={{ fontSize: 50 }} />,
      rating: 5,
    },
  ];

  return (
    <Box sx={{ bgcolor: "primary.light" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {blocks.map((block, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  height: 220,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "primary.contrastText",
                }}
              >
                {block.icon}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    lineHeight: 3,
                  }}
                >
                  {block.title}
                </Typography>
                <Rating name={`simple-controlled-${index}`} value={block.rating} readOnly />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const SomeServices = () => {
  const data = [
    { type: "image", content: bathrooms },
    {
      type: "text",
      title: "Bathrooms",
      description: "We will scrub and disinfect the tub/shower, tiles, sinks, and toilet (inside and outside).",
    },  
    { type: "image", content: kitchen },
    {
      type: "text",
      title: "Kitchen",
      description: "We will scrub the range or stove top, backsplash, and sink. We’ll clean the interior of the microwave.",
    },
    { type: "image", content: rooms },
    {
      type: "text",
      title: "All rooms",
      description: "We’ll pick up and straighten items, remove cobwebs, and dust or vacuum furniture.",
    },
    { type: "image", content: livingRoom },
    {
      type: "text",
      title: "Living room",
      description: "From sofas and armchairs to coffee tables and shelves, our dedicated team ensures a thorough cleaning of a variety of objects in your living room.",
    },
    { type: "image", content: interiorAreas },
    {
      type: "text",
      title: "Interior Areas",
      description: "Our service includes organizing items, removing cobwebs, and thoroughly dusting or vacuuming furniture.",
    },
    { type: "image", content: livingSpaces },
    {
      type: "text",
      title: "Living Spaces",
      description: "We will tidy up, eliminate cobwebs, and dust or vacuum all furniture to ensure a clean and comfortable environment.",
    }
  ];

  return (
    <Container maxWidth="xl">
      <Box mt={15} mb={8} textAlign="center">
        <Typography variant="h3" component="h1" fontWeight="bold" color="primary.contrastText">
          Some of our services
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {item.type === "image" ? (
              <img
                src={item.content}
                alt={`Imagen ${index + 1}`}
                style={{
                  width: "100%",
                  height: "325px",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            ) : (
              <Box
                sx={{
                  height: "325px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "primary.light",
                  color: "primary.contrastText",
                  borderRadius: 4,
                }}
              >
                <Typography variant="h4" fontWeight="bold">
                  {item.title}
                </Typography>
                <Typography variant="body1" padding={3}>
                  {item.description}
                </Typography>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

// eslint-disable-next-line
const ServicesBlock = () => {
  // Aquí, he agregado algunos servicios de limpieza urbana. Puedes reemplazarlos con tus propios servicios y descripciones.
  const services = [
    {
      title: "Garbage collection",
      description: "We offer regular garbage collection to keep urban spaces clean.",
      price: 15,
    },
    {
      title: "Park cleaning",
      description: "We provide park cleaning services to ensure clean and safe recreational spaces.",
      price: 20,
    },
    {
      title: "Sewage maintenance",
      description: "We perform maintenance and cleaning of sewage systems to prevent blockages and damages.",
      price: 30,
    },
    {
      title: "Recycling",
      description: "We offer recycling services to minimize the environmental impact and promote sustainability.",
      price: 10,
    },
    {
      title: "Street cleaning",
      description: "We offer street cleaning services to keep urban areas clean and improve the quality of life for residents.",
      price: 25,
    },
    {
      title: "Hazardous waste management",
      description: "We provide hazardous waste management services to safely and effectively handle hazardous materials.",
      price: 40,
    },
    {
      title: "Disinfection services",
      description: "We offer disinfection services to eliminate bacteria and viruses and ensure safe and healthy spaces.",
      price: 35,
    },
    {
      title: "Monument cleaning",
      description: "We provide monument cleaning services to preserve and maintain our cultural heritage.",
      price: 50,
    },
  ];

  return (
    <Box my={15} color={"primary.contrastText"}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" fontWeight="bold">
                  {service.title}
                </Typography>
                <Box
                  sx={{
                    top: "50%",
                    left: "100%",
                    width: "100%",
                    borderTop: "2px solid #ccc",
                    transform: "translateY(-50%)",
                  }}
                />
                <Typography variant="h5" fontWeight="bold" color="secondary.light" style={{ marginLeft: "10px" }}>
                  ${service.price}
                </Typography>
              </Box>
              <Typography variant="body1" style={{ marginBottom: "10px" }} color={"#ccc"}>
                {service.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const VideoBlock = () => {
  return (
    <Box className="video-container" mt={15}>
      <video className="video-element" autoPlay loop muted playsInline>
        <source src={Video} type="video/mp4" />
        Tu navegador no admite el elemento de video.
      </video>
      <div className="video-overlay"></div>
      {/* <div className="video-content">
        <h1 className="video-title">Título del video</h1>
        <p className="video-description">Descripción del video</p>
      </div> */}
    </Box>
  );
};

export default Home;
