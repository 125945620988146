import React, { useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewCard from "./ReviewCard"; // Importamos el componente ReviewCard
import ImageBackground from "./../../assets/testimonialsCarousel.svg"; // Agrega la imagen de fondo que quieres
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCreative, Autoplay, Pagination, Navigation } from "swiper/modules";

const ReviewCarousel = ({ reviewsData }) => {
  const StyledCarousel = styled("section")(({ theme }) => ({
    backgroundImage: `url(${ImageBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const reviewGroups = useMemo(() => {
    const groups = [];
    for (let i = 0; i < reviewsData.length; i += 2) {
      groups.push(reviewsData.slice(i, i + 2));
    }
    return groups;
  }, [reviewsData]);

  console.log(reviewGroups);

  return (
    <StyledCarousel sx={{ mb: 15 }}>
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: false,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation, EffectCreative]}
        className="mySwiper"
        speed={800}
        loop={true}
      >
        {reviewGroups.map((group, index) => (
          <SwiperSlide key={index}>
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              width="100%"
              overflow="hidden"
            >
              {group.map((review, i) => (
                <ReviewCard key={i} review={review} />
              ))}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledCarousel>
  );
};

export default ReviewCarousel;
