/* ./src/components/DashboardLayout.js */
import React from "react";
import { Box, CssBaseline } from "@mui/material";
import Sidebar from "./elements/Sidebar";
import Content from "./elements/Content";
import DashboardToolbar from "./elements/DashboardToolbar";

function DashboardLayout() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DashboardToolbar />
        <Content />
      </Box>
    </Box>
  );
}

export default DashboardLayout;
