/* ./src/components/elements/pages/DashboardPage.jsx */
import React from "react";
import { Typography } from "@mui/material";

function DashboardPage() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Bienvenido al Panel de Control
      </Typography>
      {/* Contenido adicional y componentes de tu Dashboard aquí */}
    </div>
  );
}

export default DashboardPage;
