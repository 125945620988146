import { Box, Container, Grid, Typography, IconButton, Button, capitalize } from "@mui/material";
import { Facebook, Instagram, Twitter, Google } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import CleanHandsIcon from "@mui/icons-material/CleanHands"; // Ejemplo para Airbnb
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
// Importar tu logo
import logo from "../assets/logo.png";

const Footer = () => {
  const navigate = useNavigate();

  const socialLinks = [
    {
      name: "Facebook",
      url: "https://www.facebook.com/mycleanamericanhouses",
      icon: <Facebook />,
    },
    {
      name: "Instagram",
      url: "https://instagram.com/cleanamericanhouses",
      icon: <Instagram />,
    },
    {
      name: "Google",
      url: "https://maps.app.goo.gl/fe9Lww7GdS6KcNc78?g_st=ic",
      icon: <Google />,
    },
  ];
  return (
    <Box bgcolor="primary.light" pt={5} pb={2} color={"primary.contrastText"}>
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} align="center">
            <img src={logo} alt="Logo" width={70} />
            <Typography fontWeight="bold" variant="h5" sx={{ lineHeight: "2" }}>
              Clean American Houses
            </Typography>
            <Typography
              variant="body1"
              style={{
                wordWrap: "break-word",
                maxWidth: "400px",
                color: "#ccc",
              }}
            >
              Clean American Houses is a leading residential cleaning services company specializing in houses and apartments in New Jersey, USA.
            </Typography>
          </Grid>

          <Grid item xs={12} className="footer_info">
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
              <Grid item md={4} xs={12}>
                <Typography>
                  <span style={{ fontWeight: 800 }}>Opening Hours</span> <br />
                  Open 24/7
                </Typography>
              </Grid>
              <Grid item md={4} xs={12}>
              <Typography>
                <span style={{ fontWeight: 800 }}>Telephone numbers:</span> <br />{" "}
                <a href="tel:+1 (551) 267-4779" className="link">
                  +1 (551) 267-4779
                </a>
                <span style={{ margin: '0 15px' }}>/</span>
                <a href="tel:+1 (201) 912-6837" className="link">
                  +1 (201) 912-6837
                </a>
              </Typography>

              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>
                  36 Poplar St,
                  <br /> Jersey City, NJ 07307
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} align="center">
                <Typography>
                  <span style={{ fontWeight: 800 }}>Contact us at:</span>
                </Typography>
                <Button variant="contained" startIcon={<EmailIcon />} href="mailto:hi@mycleanamericanhouses.com" style={{ margin: "10px" }}>
                   <span style={{ textTransform: 'capitalize' }}>Contact</span> 
                </Button>
                <Button
                  variant="contained"
                  startIcon={<AttachMoneyIcon />}
                  onClick={() => {
                    navigate("/services", { state: { fromReserveButton: true } });
                  }}
                  style={{ margin: "10px" }}
                >
                  <span style={{ textTransform: 'capitalize', marginLeft: "-8px" }}>Quotations</span> 
                  
                </Button>
                 <Button variant="contained" startIcon={<CleanHandsIcon />} href="mailto:airbnb@mycleanamericanhouses.com" style={{ margin: "10px" }}>
                   <span style={{ textTransform: 'capitalize' }}> <span style={{ textTransform: 'lowercase' }}>airbnb</span> Cleaning services</span> 
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} align="left" className="footer_copy">
                <Typography>Copyright © 2023-2024 Clean American Houses LLC. All rights reserved.</Typography>
              </Grid>
              <Grid item xs={12} sm={6} align="right" className="footer_socialIcons">
                {socialLinks.map((link, index) => (
                  <IconButton key={index} color="inherit" component="a" href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.icon}
                  </IconButton>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
