import { Typography } from "@mui/material";
import { useField } from "formik";

const ToggleButton = ({ name }) => {
  const [field, , helpers] = useField(name);

  const handleChange = (value) => {
    helpers.setValue(value);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="toggle-container">
        <label className={`option ${field.value === "yes" ? "selected" : ""}`}>
          <input
            type="radio"
            name={name}
            value="yes"
            checked={field.value === "yes"}
            onChange={() => handleChange("yes")}
            className="visually-hidden"
          />
          <Typography>Yes</Typography>
        </label>
        <label className={`option ${field.value === "no" ? "selected" : ""}`}>
          <input
            type="radio"
            name={name}
            value="no"
            checked={field.value === "no"}
            onChange={() => handleChange("no")}
            className="visually-hidden"
          />
          <Typography>No</Typography>
        </label>
      </div>
    </div>
  );
};

export default ToggleButton;
