import React from "react";
import "./../../styles/scrollIndicator.css";

const ScrollIndicator = ({ height }) => {
  return (
    <div className="scroll-indicator" style={{ top: height - 15 + "vh" }}>
      <svg
        className="mouse"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="M25,0C16.4,0,9.4,7,9.4,15.6v18.8C9.4,43,16.4,50,25,50s15.6-7,15.6-15.6V15.6C40.6,7,33.6,0,25,0z M37.5,34.4 c0,6.9-5.6,12.5-12.5,12.5s-12.5-5.6-12.5-12.5V15.6c0-6.9,5.6-12.5,12.5-12.5s12.5,5.6,12.5,12.5V34.4z"></path>
        <circle className="scroll-circle" cx="25" cy="11.6" r="4"></circle>
      </svg>
    </div>
  );
};

export default ScrollIndicator;
