// DashboardToolbar.jsx
import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

function DashboardToolbar() {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Typography variant="h6" noWrap>
          Panel de Control
        </Typography>
        {/* Aquí puedes agregar más elementos a la barra de herramientas si es necesario */}
      </Toolbar>
    </AppBar>
  );
}

export default DashboardToolbar;
