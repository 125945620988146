// ReviewForm.js
import React, { useState, useContext, useEffect } from "react";
import { postApi } from "services/api";
import { TextField, Button, Card, Typography, Rating, Box, Grid, CircularProgress, Dialog, DialogContent } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CommentIcon from "@mui/icons-material/Comment";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import LoginForm from "./LoginForm";
import styles from "styles/reviewForm.module.scss";

import { ReviewsContext } from "contexts/ReviewsContext";
import { AuthContext } from "contexts/AuthContext"; // Importa tu AuthContext

const StyleCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    // Estilos específicos para pantallas mayor a 600px de ancho
  },
  [theme.breakpoints.down("sm")]: {
    // Estilos específicos para pantallas menor a 600px de ancho
  },
  "@media (max-width: 500px)": {
    // Estilos específicos para pantallas menores a 300px de ancho
    padding: "15px",
    width: "90%",
  },
}));

const ReviewForm = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { dispatch } = useContext(ReviewsContext);
  const { state } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [form, setForm] = useState({
    first_name: state.user ? state.user.first_name : "", // Usa los datos del usuario autenticado
    last_name: state.user ? state.user.last_name : "",
    email: state.user ? state.user.email : "",
    comment: "comentario",
    rating: 4,
  });

  const initialForm = {
    first_name: "",
    last_name: "",
    email: "",
    comment: "",
    rating: 0,
  };

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let valuetwo = name === "rating" ? parseInt(value) : value;
    setForm({ ...form, [e.target.name]: valuetwo });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const formData = new FormData(e.target);
    const errors = {};

    //Agregar errores al array
    !form.first_name && (errors.first_name = "First Name is required");
    !form.last_name && (errors.last_name = "Last Name is required");
    !emailRegEx.test(form.email) && (errors.email = "Email is not valid");
    !form.comment && (errors.comment = "Comment is required");
    !form.rating > 0 && (errors.rating = "The rating must be at least one star");

    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const response = await postApi(formData, "/reviews"); // Espera a que la petición se complete

        if (response.status === "success") {
          dispatch({ type: "addReview", review: response.data.review }); // Añadir la nueva reseña al contexto
          !state.isLoggedIn && setForm(initialForm);
          Swal.fire("¡Success!", "Your feedback has been sent", "success"); // Muestra el mensaje de éxito
        } else if (response.status === "error") {
          let errorMessage = response.message;
          // Iterar sobre los errores de los campos y concatenar los mensajes
          let fieldErrorMessages = Object.values(response.errors).flat().join("<br>");
          Toast.fire({
            icon: "error",
            html: `${errorMessage}: <br> ${fieldErrorMessages}`,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "There was an error submitting your review, please contact technical support.",
          });
        }
      } catch (error) {
        Swal.fire("Error", "There was an error submitting your review", "error"); // Muestra un mensaje de error
      } finally {
        setLoading(false); // Detiene la carga, sin importar si la petición tuvo éxito o no
      }
    } else {
      //se asigna el array de errores al estado
      setFormErrors(errors);
    }
  };

  const handleLoginClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (state.user) {
      setForm((prevForm) => ({
        ...prevForm,
        first_name: state.user.first_name,
        last_name: state.user.last_name,
        email: state.user.email,
      }));
    } else {
      setForm(initialForm);
    }
    // eslint-disable-next-line
  }, [state.user]);

  return (
    <StyleCard className={styles.card} sx={{ p: 8, borderRadius: 5, bgcolor: "background.light", width: "50%" }}>
      <Typography variant="h5" component="h2" align="center" sx={{ py: 2 }}>
        Give us your opinion
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent="center">
          <Grid item xs={11} sx={{}}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <Box sx={{ p: 1, mb: 1 }}>
                <Box style={{ position: "relative", width: "100%" }}>
                  <PersonIcon sx={{ position: "absolute", bottom: "-15px", left: 0, transform: "translateY(-50%)", marginRight: 1, color: "primary.main", fontSize: 27 }} />
                  <Box sx={{ ml: 4 }}>
                    <TextField
                      fullWidth
                      id="revfirst_name"
                      label="First Name"
                      variant="standard"
                      name="first_name"
                      value={form.first_name}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: state.isLoggedIn,
                      }}
                      className={formErrors.first_name ? "inputError" : ""}
                    />
                  </Box>
                  {formErrors.first_name && (
                    <Box className="errorText" sx={{ position: "absolute", ml: 4 }}>
                      {formErrors.first_name}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: 1, mb: 1 }}>
                <Box style={{ position: "relative", width: "100%" }}>
                  <PersonIcon sx={{ position: "absolute", bottom: "-15px", left: 0, transform: "translateY(-50%)", marginRight: 1, color: "primary.main", fontSize: 27 }} />
                  <Box sx={{ ml: 4 }}>
                    <TextField
                      fullWidth
                      id="revlast_name"
                      label="Last Name"
                      name="last_name"
                      variant="standard"
                      value={form.last_name}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: state.isLoggedIn,
                      }}
                      className={formErrors.last_name ? "inputError" : ""}
                    />
                  </Box>
                  {formErrors.last_name && (
                    <Box className="errorText" sx={{ position: "absolute", ml: 4 }}>
                      {formErrors.last_name}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: 1, mb: 1 }}>
                <Box style={{ position: "relative", width: "100%" }}>
                  <EmailIcon sx={{ position: "absolute", bottom: "-15px", left: 0, transform: "translateY(-50%)", marginRight: 1, color: "primary.main", fontSize: 27 }} />
                  <Box sx={{ ml: 4 }}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      variant="standard"
                      value={form.email}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: state.isLoggedIn,
                      }}
                      className={formErrors.email ? "inputError" : ""}
                    />
                  </Box>
                  {formErrors.email && (
                    <Box className="errorText" sx={{ position: "absolute", ml: 4 }}>
                      {formErrors.email}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: 1, mb: 1 }}>
                <Box style={{ position: "relative", width: "100%" }}>
                  <CommentIcon sx={{ position: "absolute", bottom: "-15px", left: 0, transform: "translateY(-50%)", marginRight: 1, color: "primary.main", fontSize: 27 }} />
                  <Box sx={{ ml: 4 }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="comment"
                      label="Comment"
                      name="comment"
                      variant="standard"
                      value={form.comment}
                      onChange={handleChange}
                      className={formErrors.comment ? "inputError" : ""}
                    />
                  </Box>
                  {formErrors.comment && (
                    <Box className="errorText" sx={{ position: "absolute", ml: 4 }}>
                      {formErrors.comment}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: 1, mb: 1 }}>
                <Box style={{ position: "relative", width: "100%" }}>
                  <Box sx={{ textAlign: "center" }}>
                    <Rating name="rating" value={form.rating} onChange={handleChange} className={`${styles.rating} ${formErrors.rating ? "inputError" : ""}`} size="large" />
                  </Box>
                  {formErrors.rating && (
                    <Box className="errorText" sx={{ position: "absolute", ml: 4 }}>
                      {formErrors.rating}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
                <Button type="submit" className={styles.button} sx={{ py: 1, px: 7, borderRadius: 10 }}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                      Enviar
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>

      {!state.isLoggedIn && (
        <Box>
          <Typography variant="body1" align="center" sx={{ py: 4 }}>
            Or login using
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // Esto añade un espacio entre los elementos del contenedor
              pb: 2,
            }}
          >
            {/* <GoogleLoginButton onSuccess={handleSuccess} onError={handleError} /> */}
            {/*           <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#fff",
              borderRadius: "50%",
              width: 60,
              height: 60,
              boxShadow: 3,
              cursor: "pointer",
            }}
          >
            <img src="assets/icons/google.png" width={30} alt="google icon" />
          </Box> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "#fff",
                borderRadius: "50%",
                width: 60,
                height: 60,
                boxShadow: 3,
                cursor: "pointer",
              }}
              onClick={handleLoginClick}
            >
              <img src="assets/icons/email.png" width={30} alt="email icon" />
            </Box>
          </Box>
        </Box>
      )}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth PaperProps={{ style: { maxHeight: "100%", overflow: "hidden" } }}>
        <DialogContent dividers style={{ padding: 0 }}>
          <LoginForm onClose={handleCloseModal} />
        </DialogContent>
      </Dialog>
    </StyleCard>
  );
};

export default ReviewForm;
