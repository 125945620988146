// AuthContext.js
import React, { useReducer, createContext, useEffect } from "react";
import Cookies from "js-cookie";

const initialState = {
  isLoggedIn: false,
  user: null,
};

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const user = Cookies.get("user");
    if (user && user !== "undefined") {
      dispatch({ type: "LOGIN", payload: JSON.parse(user) });
    }
  }, []);

  useEffect(() => {
    if (state.isLoggedIn) {
      Cookies.set("user", JSON.stringify(state.user), { expires: 1 }); // La sesión dura 1 día
    } else {
      Cookies.remove("user");
    }
  }, [state.isLoggedIn, state.user]); // Añade state.user aquí

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};
