import React, { useContext, useMemo, useEffect } from "react";
import testimonials from "../assets/testimonials.png";
import { styled } from "@mui/material/styles";
import { Box, Typography, Container, Grid, Rating, Card, LinearProgress } from "@mui/material";
import ScrollIndicator from "./elements/ScrollIndicator";
import ReviewCarousel from "./elements/ReviewCarousel";
import ReviewForm from "./elements/ReviewForm";
import { ReviewsProvider, ReviewsContext } from "contexts/ReviewsContext";
import styles from "styles/Testimonials.module.scss";

const Testimonials = () => {
  const { state } = useContext(ReviewsContext);
  const reviewsData = state.reviewsData;
  const totalReviews = reviewsData.length;

  const blocks = [
    {
      title: "1K",
      description: "Happy Customers",
    },
    {
      title: "#1",
      description: "Number 1 cleaning company",
    },
    {
      title: totalReviews.toString(),
      description: "Number of reviews",
    },
  ];

  const avgRating = useMemo(() => {
    const sum = reviewsData.reduce((total, review) => total + review.rating, 0);
    return sum / reviewsData.length;
  }, [reviewsData]);

  // Agrupa las reseñas por calificación
  const ratingGroups = useMemo(() => {
    return reviewsData.reduce((groups, review) => {
      groups[review.rating] = (groups[review.rating] || 0) + 1;
      return groups;
    }, {});
  }, [reviewsData]);

  // Actualiza los cálculos cuando reviewsData cambie
  useEffect(() => {}, [reviewsData]);

  return (
    <>
      <section className={styles.banner_container} style={{ height: "80vh", position: "relative" }}>
        <div
          className={styles.banner_background}
          style={{ backgroundImage: `url(${testimonials})` }}
        />
        <div className={styles.banner_TxtContainer} style={{ position: "relative", zIndex: 2 }}>
          <Typography align="left" className={styles.banner_title}>
            Inspiring Experiences: Testimonials from Our Satisfied Customers
          </Typography>
          <Box
            sx={{
              pt: 3,
            }}
          >
            <Typography align="left" className={styles.banner_description}>
              Discover the stories of satisfaction and transformation shared by our valued customers
              at Clean American Houses. From gleaming homes to healthy living spaces, our urban
              cleaning services have made a positive impact on people's lives.
            </Typography>
          </Box>
        </div>
        <ScrollIndicator height={80} />
      </section>
      <Box sx={{ bgcolor: "primary.light", mb: 10, mt: 5 }}>
        <Container maxWidth="xl">
          <Grid container spacing={3} sx={{ p: 5 }}>
            {blocks.map((block, index) => (
              <Grid item xs={12} md={4} key={index} style={{ padding: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "background.main",
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 800,
                    }}
                  >
                    {block.title}
                  </Typography>
                  <Typography variant="h5" align="center">
                    {block.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <ReviewCarousel reviewsData={reviewsData} />

      <Container maxWidth="xl" sx={{ pb: 15 }}>
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
        >
          <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
            <ReviewForm />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center", height: "100%" }}
          >
            <Card sx={{ borderRadius: 5, padding: 5, width: "70%" }}>
              <Typography className={styles.rating_title}>Reviews and ratings</Typography>
              <Box display="flex" alignItems="center" className={styles.rating_container}>
                <Typography variant="h1" fontWeight={800} className={styles.rating_heading}>
                  {avgRating.toFixed(1)}
                </Typography>
                <Box ml={2} className={styles.rating_info}>
                  <Rating
                    name="avg-rating"
                    value={avgRating}
                    readOnly
                    sx={{ color: "secondary.main" }}
                    className="rating-stars"
                  />
                  <Typography
                    variant="body2"
                    className="rating-count"
                  >{`Based on ${reviewsData.length} ratings`}</Typography>
                </Box>
              </Box>

              {[5, 4, 3, 2, 1].map((rating) => {
                const count = ratingGroups[rating] || 0;
                const percentage = ((count / reviewsData.length) * 100).toFixed(1);
                return (
                  <Box key={rating} display="flex" alignItems="center" mb={1}>
                    <Rating
                      name={`stars-${rating}`}
                      value={rating}
                      readOnly
                      sx={{ color: "secondary.main" }}
                    />
                    <Box width="100%" mr={1} ml={1}>
                      <LinearProgress variant="determinate" value={Number(percentage)} />
                    </Box>
                    <Typography variant="body1">{`${
                      reviewsData.length > 0 ? percentage : 0
                    }%`}</Typography>
                  </Box>
                );
              })}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const TestimonialsWithProvider = () => {
  return (
    <ReviewsProvider>
      <Testimonials />
    </ReviewsProvider>
  );
};

export default TestimonialsWithProvider;
