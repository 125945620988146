/* ./src/components/elements/pages/UsersPage.jsx */
import React from "react";
import { Typography } from "@mui/material";

function UsersPage() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Gestión de Usuarios
      </Typography>
      {/* Componentes para gestionar usuarios aquí */}
    </div>
  );
}

export default UsersPage;
