import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, IconButton, Divider, Toolbar } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "./../../assets/logo.png";

// Define un array de objetos que representan los ítems del menú y sus subítems
const menuItems = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  {
    text: "Web Apps",
    icon: <EmailIcon />,
    items: [
      { text: "Email", icon: <EmailIcon />, path: "/dashboard/email" },
      { text: "Chat", icon: <ChatIcon />, path: "/dashboard/chat" },
      { text: "Calendar", icon: <CalendarIcon />, path: "/dashboard/calendar" },
      // otros ítems de aplicaciones web...
    ],
  },
  { text: "Usuarios", icon: <PeopleIcon />, path: "/dashboard/users" },
  // más ítems o grupos de menú...
];
const drawerWidthExpanded = 240;
const drawerWidthCollapsed = 60;

function Sidebar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerOpen ? drawerWidthExpanded : drawerWidthCollapsed,
        "& .MuiDrawer-paper": {
          width: drawerOpen ? drawerWidthExpanded : drawerWidthCollapsed,
          transition: "width 0.3s ease-in-out",
          overflowX: "hidden", // Evita el desbordamiento de contenido en el eje X
        },
      }}
      open={drawerOpen}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between", // Asegura que los elementos estén separados
          alignItems: "center",
          px: [1],
        }}
      >
        {drawerOpen ? (
          // Contenedor para el logo y el botón cuando el menú está expandido
          <React.Fragment>
            <img src={logo} alt="logo" style={{ height: "30px" }} />
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          </React.Fragment>
        ) : (
          // Solo el botón del menú cuando el menú está colapsado
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => (item.items ? handleClick(index) : navigate(item.path))}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              {item.items && (open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
            </ListItem>
            {item.items && (
              <Collapse in={open[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.items.map((subItem, subIndex) => (
                    <ListItem button key={`sub-${subIndex}`} sx={{ pl: 4 }} onClick={() => navigate(subItem.path)}>
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={subItem.text} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;
