import * as yup from "yup";

const validationRules = {
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "Emails must match")
    .required("Confirm Email is required"),
  address: yup.string().required("Address is required"),
  typeOfHome: yup.string().required("Type of Home is required"),
  roomsToClean: yup.array().min(1, "You must select at least one option."),
  numberOfBedrooms: yup
    .number()
    .typeError("Number of Bedrooms must be a number")
    .min(0, "Number of Bedrooms must be 1 or more")
    .required("Number of Bedrooms is required"),
  numberOfBathrooms: yup
    .number()
    .typeError("Number of Bathrooms must be a number")
    .min(0, "Number of Bathrooms must be 1 or more")
    .required("Number of Bathrooms is required"),
  hasPets: yup.string().required("Please select an option for Pets"),
  hasCarpet: yup.string().required("Please select an option for Carpet"),
  typeOfServices: yup.string().required("Type of Services is required"),
  preferredDays: yup.string().required("Preferred Days is required"),
  preferredTime: yup.string().required("Preferred Time is required"),
  willProvideCleaningProducts: yup
    .string()
    .required("Please select an option for Cleaning Products"),
  additionalDetails: yup.string(),
  additionalServices: yup.array(),
  images: yup.array(),
};

export default validationRules;
