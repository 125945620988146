import React, { useState } from "react";
import { Container, Grid, Typography, Box, TextField, Button, IconButton, FormControl, InputLabel, OutlinedInput, CircularProgress } from "@mui/material";
/* import logo_rounded from "./../assets/logo_rounded.png"; */
import backgroundImage from "./../assets/bg_contact.jpg";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { postApi } from "./../services/api";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";

const Contact = () => {
  const phoneNumber = "+1 (551) 267-4779";
  const phoneNumber2 = "+1 (201) 912-6837";

  // Add statuses for each form field
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyAbout: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      formIsValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required";
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
      formIsValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
      formIsValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
      formIsValid = false;
    }

    // Here you can add more validations as needed.

    return { formIsValid, errors };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { formIsValid, errors } = validateForm();

    if (!formIsValid) {
      // Here you would handle errors, for example, by displaying them to the user.
      // For now, we will only print them on the console.
      console.log(errors);

      // Show error message with SweetAlert2
      Swal.fire({
        title: "Error",
        html: Object.values(errors)
          .map((e) => (Array.isArray(e) ? e.join(", ") : e))
          .join("<br />"), // Verify if it is an array before doing join
        icon: "error",
        confirmButtonText: "Ok",
      });

      return; // Avoid sending the form if there are errors
    }

    setIsSubmitting(true);

    // If the form is valid, continue with the submission.
    // Prepare data to send
    const dataToSend = new FormData();
    dataToSend.append("name", formData.name);
    dataToSend.append("email", formData.email);
    dataToSend.append("phone", formData.phone);
    dataToSend.append("companyAbout", formData.companyAbout);
    dataToSend.append("message", formData.message);

    try {
      const response = await postApi(dataToSend, "/contact/send");
      console.log(response);
      Swal.fire({
        title: "Success!",
        text: "Your message has been sent successfully.",
        icon: "success",
        confirmButtonText: "Ok",
      });
      // Clear the form fields
      setFormData({
        name: "",
        email: "",
        phone: "",
        companyAbout: "",
        message: "",
      });
    } catch (error) {
      // Error handling in case of shipment failure
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "There was a problem sending your message. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsSubmitting(false); // Set submitting state to false after submission
    }
  };

  const steps = [
    { number: "01", text: "We schedule a call for your service", icon: ">", id: "step1" },
    { number: "02", text: "Engage with clients to understand their needs and goals", icon: ">", id: "step2" },
    { number: "03", text: "Perform a walkthrough of the client's home to assess cleaning needs", icon: ">", id: "step3" },
    { number: "04", text: "Provide a detailed cleaning plan and cost estimate to the client", icon: ">", id: "step4" },
    { number: "05", text: "Execute the cleaning service according to the agreed plan", icon: ">", id: "step5" },
    { number: "06", text: "Follow up with clients to ensure satisfaction and address any concerns", icon: ">", id: "step6" },
  ];

  return (
    <Box
      sx={{
        width: "100%", // This ensures that the Box is spread over the entire width
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // This will cause the image to cover the entire available space.
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        pt: {
          xs: 0, // menos de 600px
          sm: 0, // más de 600px
          md: 15, // más de 900px
        },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
          {/* Content Section */}
          <Grid item xs={12} md={6} color={"primary.contrastText"} sx={{ mt: { xs: 10, md: 0 } }}>
            {/* Add your logo or icon here if necessary. */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // Column on mobile, row on larger screens
                alignItems: "center",
                justifyContent: { xs: "center", md: "left" }, // Focuses the content on the main axis
                textAlign: "center", // Ensure that the text is also centered.
                my: 3,
              }}
            >
              {/* Text */}
              <Typography
                sx={{
                  mb: { xs: 2, sm: 0 },
                  mr: 2,
                }}
              >
                WORK WITH US
              </Typography>
              {/* Logo */}
              {/* <Box>
                <img src={logo_rounded} alt="Logo" style={{ height: "50px" }} />
              </Box> */}
            </Box>

            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "primary.contrastText",
                fontSize: { xs: "1.5rem", md: "2.5rem" },
                textAlign: { xs: "center", md: "left" },
                zIndex: 2,
              }}
            >
              DISCOVER EXPERT CLEANING SOLUTIONS FOR YOUR HOME
            </Typography>
            <Typography
              gutterBottom
              sx={{
                color: "primary.contrastText",
                fontSize: { xs: "1.2rem", md: "1.5rem" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Our team is prepared to transform your home with premium cleaning services designed specifically for your individual requirements.
            </Typography>

            {/* Telephone number section*/}
            <Grid container alignItems="center" justifyContent="center" my={5}>
              {/* Icon column */}
              <Grid item>
                <a href={`tel:${phoneNumber.replace(/-|\s/g, "")}`} style={{ textDecoration: "none" }}>
                  <IconButton
                    sx={{
                      color: "white",
                      backgroundColor: "primary.main",
                      borderRadius: "50%",
                      // Aumenta el tamaño del botón
                      width: (theme) => theme.spacing(9), // Uses a larger value for a larger button
                      height: (theme) => theme.spacing(9), // Uses a larger value for a larger button
                      // Add shadow if desired for a lifting effect.
                      boxShadow: (theme) => theme.shadows[4],
                      "&:hover": {
                        backgroundColor: "rgb(232, 30, 37, 0.5)", // Dark color with less transparency when hovering
                        // Or any other style you want to apply on hover
                      },
                    }}
                  >
                    <PhoneIphoneIcon
                      sx={{
                        // Increase icon size
                        fontSize: "2rem", // Change this to adjust the size of the icon
                      }}
                    />
                  </IconButton>
                </a>
              </Grid>

              {/* Text column */}
              <Grid item xs>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Call us at:
                  </Typography>
                  <a href={`tel:${phoneNumber.replace(/-|\s/g, "")}`} style={{ textDecoration: "none", color: "white" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: "primary.contrastText",
                        fontSize: { xs: "1.2rem", md: "1.5rem" },
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {phoneNumber}
                    </Typography>
                  </a>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <a href={`tel:${phoneNumber2.replace(/-|\s/g, "")}`} style={{ textDecoration: "none", color: "white" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: "primary.contrastText",
                        fontSize: { xs: "1.2rem", md: "1.5rem" },
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {phoneNumber2}
                    </Typography>
                  </a>
                </Box>
              </Grid>
            </Grid>

            {/* Numbered sections */}
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <Typography variant="h6" gutterBottom>
                How do we work?
              </Typography>
              <Grid container spacing={2}>
                {steps.map((step) => (
                  <Grid item xs={12} sm={6} key={step.id}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(33, 33, 33, 0.5)", // Background color of the step box
                        p: 2,
                        borderRadius: 5, // The radius of the step box corner
                        mb: 1, // Bottom margin to separate the steps
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: "40px", // Minimum width to maintain the shape of the circle
                          height: "40px", // Fixed height for circle
                          borderRadius: "50%", // This ensures that the edges are rounded
                          bgcolor: "primary.main", // Circle background color
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mr: 2, // Margin to the right of the circle
                          "&:hover": {
                            backgroundColor: "rgb(232, 30, 37, 0.5)", // Dark color with less transparency when hovering
                            // Or any other style you want to apply on hover
                          },
                          cursor: "pointer",
                        }}
                      >
                        <Typography variant="subtitle1" color="primary.contrastText" sx={{ lineHeight: "1" }}>
                          {step.number}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" color="primary.contrastText">
                        {step.text}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          {/* Form Section */}
          <Grid
            item
            xs={12}
            md={6}
            mb={15}
            sx={{
              display: "flex", // Sets the form container as a flexbox
              alignItems: "center", // Aligns child elements vertically in the center
              justifyContent: "center", // Centers the child elements horizontally
              height: "100%", // Make sure that the Grid item has a defined height for vertical alignment to work.
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)", // Change this according to your color palette
                padding: 3,
                borderRadius: 8,
                boxShadow: 15, // Adjust this to match your design
                mx: "auto",
                maxWidth: {
                  xs: "100%", // menos de 600px
                  sm: "60%", // más de 600px
                  md: "60%", // más de 900px
                },
              }}
            >
              <Typography variant="h6" component="h2">
                Please fill in the information below
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField label="Your name" fullWidth margin="normal" name="name" onChange={handleChange} value={formData.name} />
                <TextField label="Email" fullWidth margin="normal" name="email" onChange={handleChange} value={formData.email} />
                <InputMask mask="+1 (999) 999-9999" value={formData.phone} onChange={handleChange} disabled={isSubmitting} maskChar=" ">
                  {() => <TextField label="Phone" fullWidth margin="normal" name="phone" />}
                </InputMask>

                <TextField label="Company about" fullWidth margin="normal" multiline name="company" onChange={handleChange} value={formData.company} />
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel htmlFor="message-area">Describe your message</InputLabel>
                  <OutlinedInput
                    id="message-area"
                    label="Describe your message"
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={8}
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    "&:hover": {
                      color: "primary.dark", // Dark color with less transparency when hovering
                      fontWeight: "600px",
                      // Or any other style you want to apply on hover
                    },
                  }}
                >
                  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "SUBMIT MESSAGE"}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
